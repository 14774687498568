import * as React from "react";
import _ from "lodash";
import { Fragment } from "react";
import {
  IFilterData,
  BaseListComponentState,
} from "../../../components/BaseListComponent";
import { printPDF } from "../../../services/utility/textFormatService";
import {
  createFilterData,
  mapEntityList,
} from "./../../../services/utility/autoMapperService";
import BaseListComponent from "./../../../components/BaseListComponent";
import usersService from "../../../services/auth/usersService";
import ItemsTable, {
  filterFieldData,
} from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "./../../../components/tableComponents/tableFilterPane";
import branchesService from "../../../services/auth/branchesService";
import UserDetail from "./userDetail";
import { getCurrentUsersRoles } from "../../../services/auth/authService";
import EditUser from "./editUser";
import AuditTrailDetail from "../../auditTrail/auditTrailDetail";

export interface UsersProps {}

export interface UsersState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  updateBaseListState: boolean;
  toUpdate: boolean;
}

class UsersList extends BaseListComponent<UsersState> {}

class Users extends React.Component<UsersProps, UsersState> {
  constructor(props: UsersProps) {
    super(props);
    this.state = {
      data: createFilterData([
        "firstName",
        "lastName",
        "userName",
        "branch",
        "status",
      ]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "firstName", fieldType: "text", value: "" },
        { name: "lastName", fieldType: "text", value: "" },
        { name: "userName", fieldType: "text", value: "" },
        {
          name: "branch",
          fieldType: "select",
          options: [],
          value: "",
        },
        {
          name: "status",
          fieldType: "select",
          options: ["Active", "Inactive"],
          value: "",
        },
      ],
      item: {},
      showDetailsModal: false,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      updateBaseListState: false,
      toUpdate: true,
    };
  }

  //   generateInitialFieldValues = async () =>{
  //     const branches = await branchesService.retrieveAllBranches();
  //       const branchNames = !_.isEmpty(branches)
  //         ? branches.data.map((item) => item.name)
  //         : [];
  //       let filterValueInput = [...this.state.filterValueInput];
  //       console.log("got branches pre", filterValueInput);
  //       filterValueInput = filterValueInput.map((filterField) =>
  //         filterField.name === "branch"
  //           ? { ...filterField, options: branchNames }
  //           : filterField
  //       );
  //       console.log("got branches", filterValueInput);
  //       return filterValueInput;
  //   }

  handlePageChange = async (currentPage: number, pageSize:number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    this.setState({ fetching: true, updateBaseListState: false });
    let dataClone =
      typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const branches = await branchesService.retrieveAllBranches();
      if (currentPage === 1 && this.state.toUpdate === true) {
        const branchNames = !_.isEmpty(branches)
          ? branches.data.map((item) => item.name)
          : [];
        let filterValueInput = [...this.state.filterValueInput];
        filterValueInput = filterValueInput.map((filterField) =>
          filterField.name === "branch"
            ? { ...filterField, options: branchNames }
            : filterField
        );
        this.setState({
          filterValueInput: filterValueInput,
          updateBaseListState: true,
          toUpdate: false,
        });
      }
      const branchObj = !_.isEmpty(branches)
        ? branches.data.map((item) => {
            return { name: item.name, id: item.id };
          })
        : [];

      const selectedBranchObj = !_.isEmpty(branchObj)
        ? branchObj.filter((item) => item.name === dataClone["branch"].item2)[0]
        : {};

      //fix to get correct branchId
      const newDataArray = [
        "firstName",
        "lastName",
        "userName",
        "branchId",
        "status",
      ];
      let filterData: any = {};
      newDataArray.forEach((header) => {
        if (header === "branchId") {
          filterData[header] = !_.isEmpty(selectedBranchObj)
            ? { item1: dataClone["branch"].item1, item2: selectedBranchObj.id }
            : { item1: "like", item2: "" };
        } else {
          filterData[header] = dataClone[header];
        }
      });
      //   console.log("checkin issues", filterData);
      const users = await usersService.filterUsers(
        filterData,
        pageSize,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: users.data.item2,
          properties: [
            { oldName: "firstName", newName: "firstName" },
            { oldName: "lastName", newName: "lastName" },
            { oldName: "username", newName: "userName" },
            { oldName: "phoneNo", newName: "phoneNumber" },
            {
              isTag: true,
              oldName: "status",
              newName: "status",
              success: "Active",
              danger: "Inactive",
              default: "default",
              primary: "primary",
              secondary: "secondary",
              warning: "warning",
              light: "light",
              dark: "dark",
            },
          ],
        }),
        currentPage,
        pageSize,
        totalSize: users.data.item1,
        fetching: false,
      });
    } catch (ex) {
      // console.log("caught exception", ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
        this.setState({ fetching: false });
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleOnExport = async (type: string) => {
    try {
      switch (type) {
        case "XLSX":
        case "CSV":
          return await usersService.downloadReport(type);
        case "PDF":
          const response = await usersService.downloadReportPDF();
          printPDF(response.data);
          break;
        default:
          break;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 499
      ) {
        // toast.error("Bad Request");
        // console.log(ex.response);
        // errors["response"] = "Internal server error.";
        // console.log(errors);
      } else {
        // toast.error("Internal server error. Please contact the admin.");
        //this.setState({ errors, fetching: false });
      }
    }
  };

  fetchItemById = async (item: any) => {
    this.setState({ fetchingDetail: true, updateBaseListState: false });
    const user = await usersService.retrieveUserById(item.id);
    this.setState({ item: user.data, fetchingDetail: false });
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  async componentDidMount() {
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
  }

  render() {
    const {
      // data,
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
    } = this.state;
    // console.log("asfsdf", this.state.filterValueInput);
    return (
      <Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Users</b>
              </h3>
            </div>
            <UsersList
              pageChangeHandler={this.handlePageChange}
              fetchingDetail={fetchingDetail}
              updateState={this.state.updateBaseListState}
              initialValues={{
                data: createFilterData([
                  "firstName",
                  "lastName",
                  "userName",
                  "branch",
                  "status",
                ]) as IFilterData,
                fetching: false,
                showDetailsModal: false,
                showEditModal: false,
                showTableFilterModal: false,
                showTrailModal: false,
                tableItems: [],
                filterValueInput: this.state.filterValueInput,
                item: {},
                jsonBefore: {},
                jsonAfter: {},
                fetchingTrail: false,
                entityName: "Recova.Auth.User",
              }}
              tableItems={tableItems}
              item={item}
              DetailsModal={({
                item,
                onToggleDetailsModal,
                onToggleEditModal,
                showDetailsModal,
                showEditModal,
                fetchTrailItem,
                fetchingTrail,
                jsonAfter,
                jsonBefore,
                onToggleTrailModal,
                showTrailModal,
                trailItem,
                trailMapper,
              }) => (
                <Fragment>
                  <UserDetail
                    showDetailsModal={showDetailsModal}
                    toggleDetailsModal={onToggleDetailsModal}
                    toggleEditModal={onToggleEditModal}
                    showTrail={() => onToggleTrailModal(item)}
                    currentUserRoles={getCurrentUsersRoles()}
                    item={item}
                  />
                  <EditUser
                    showEditModal={showEditModal}
                    toggleEditModal={(reloadDetails: boolean, item: any) => {
                      onToggleEditModal();
                      if (reloadDetails === true) {
                        this.fetchItemById(item);
                      }
                      this.setState({
                        showDetailsModal: !this.state.showDetailsModal,
                      });
                    }}
                    item={item}
                  />
                  <AuditTrailDetail
                    showDetailsModal={showTrailModal}
                    toggleDetailsModal={onToggleTrailModal}
                    fetchNextTrail={fetchTrailItem}
                    item={trailItem}
                    trailMapper={trailMapper}
                    fetchingDetail={fetchingTrail}
                    jsonBefore={jsonBefore}
                    jsonAfter={jsonAfter}
                  />
                </Fragment>
              )}
              ListPayLoad={({
                data,
                showTableFilterModal,
                tableItems,
                onToggleDetailsModal,
                onToggleTableFilterModal,
                onHandleFilterItemRemove,
                filterValueInput,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card no-b">
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Users"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                                onToggleDetailsModal();
                              }}
                              handleOnExport={this.handleOnExport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            ></UsersList>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Users;
