import React, { Fragment } from "react";
import { decodeToken } from "react-jwt";
import BaseListComponent, { BaseListComponentState, IFilterData } from "../../../components/BaseListComponent";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { createFilterData, mapEntityList } from "./../../../services/utility/autoMapperService";
import rolesService from "../../../services/auth/rolesService";
import appraisalInstitutionService from "../../../services/hybridAppraisal/appraisalInstitutionService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import loanRequestService from "../../../services/hybridAppraisal/loanRequestService";
import AppraisalWorkflow from "./appraisalWorkflow";
import NewApplication from "./appraisalDetailPages/newApplication";
// import DataValidationView from "./appraisalDetailPages/dataValidationView";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";
// import OfferLetterScreen from "./appraisalDetailPages/offerLetterScreen";
// import DocumentCollection from "./appraisalDetailPages/postApprovalDcoumentScreen";
// import PreDisbursement from "./appraisalDetailPages/preDisbursementScreen";
// import LoanDisbursement from "./appraisalDetailPages/loanDisbursementScreen";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import GroupNewApplication from "./appraisalDetailPages/newApplication";
import DataValidationView from "./appraisalDetailPages/dataValidationView";
import GroupOfferLetterScreen from "./appraisalDetailPages/DocumentGenerationScreen";
import GroupDocumentCollection from "./appraisalDetailPages/PostApprovalDocumentCollectionScreen";
import groupLoanRequestService from "../../../services/hybridAppraisal/groupLoanRequestService";
import PreDisbursement from "./appraisalDetailPages/preDisbursementScreen";
import LoanDisbursement from "./appraisalDetailPages/loanDisbursementScreen";
import GroupAppraisalWorkflowModal from "./groupAppraisalWorkFlowModal";

export interface LoanRequestsProps { }

export interface LoanRequestsState extends BaseListComponentState {
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    totalSize: number;
    fetchingDetail: boolean;
    filterValueInput: filterFieldData;
    item: any;
    toUpdate: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    updateAvailable: boolean;
    userRoles: any;
    systemProcess: any;
    showTable: boolean;
    showAppraisalProcess: boolean;

}

class LoanRequestsList extends BaseListComponent<LoanRequestsState> { }

class ViewAllGroupLoanRequests extends React.Component<LoanRequestsProps, LoanRequestsState> {
    _isMounted = false;
    constructor(props: LoanRequestsProps) {
        super(props);
        this.state = {
            data: createFilterData(["CustomerId", "CustomerTin", "IdentificationNumber", "status"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "CustomerId", fieldType: "text", value: "" },
                { name: "CustomerTin", fieldType: "text", value: "" },
                { name: "IdentificationNumber", fieldType: "text", value: "" },
                {
                    name: "status",
                    fieldType: "select",
                    options: ["Created", "DataValidation", "DocumentGeneration", "DocumentCollection", "PreDisbursement", "LoanDisbursement", "LoanAppraisal"],
                    value: "",
                }
            ],
            item: {},
            showDetailsModal: false,
            showTable: true,
            showEditModal: false,
            showTableFilterModal: false,
            showTrailModal: false,
            toUpdate: true,
            userRoles: [],
            systemProcess: [],
            showAppraisalProcess: false,
            documentsToUpload: [],
            documentsToDelete: [],
            updateAvailable: false,
        };
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        if (this._isMounted) {
            this.setState({ fetching: true });
        }
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        let dataClone: IFilterData = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        // console.log("raw",data)
        dataClone["InstitutionCode"] = { item1: "=", item2: decodedToken?.["InstitutionCode"] }
        dataClone["currentLevel"] = { item1: "between", item2: `${0} and ${99}` }

        if (dataClone.CustomerId.item2 !== "") {
            dataClone["JsonData"] = { item1: "like", item2: `\\"CustomerID\\":${dataClone.CustomerId.item2}` }
        }
        if (dataClone.CustomerTin.item2 !== "") {
            dataClone["TIN"] = dataClone.CustomerTin
        }
        if (dataClone.status.item2 !== "") {
            switch (dataClone.status.item2) {
                case "Created":
                    dataClone["currentLevel"] = { item1: "=", item2: `-1` }
                    break;
                case "Created":
                    dataClone["currentLevel"] = { item1: "=", item2: `0` }
                    break;
                case "DocumentGeneration":
                    dataClone["currentLevel"] = { item1: "=", item2: `100` }
                    break;
                case "DocumentCollection":
                    dataClone["currentLevel"] = { item1: "=", item2: `101` }
                    break;
                case "PreDisbursement":
                    dataClone["currentLevel"] = { item1: "=", item2: `102` }
                    break;
                case "LoanDisbursement":
                    dataClone["currentLevel"] = { item1: "=", item2: `103` }
                    break;
                case "LoanAppraisal":
                    dataClone["currentLevel"] = { item1: "between", item2: `${1} and ${99}` }
                    break;
                default:
                    break;
            }
        }
        if (dataClone.IdentificationNumber.item2 === "") {
            delete dataClone.IdentificationNumber
        }
        delete dataClone.CustomerId
        delete dataClone.CustomerTin
        delete dataClone.status
        // console.log("processed",dataClone)
        try {
            const groupLoanRequests = await groupLoanRequestService.filterLoanRequests(
                dataClone,
                pageSize,
                currentPage
            );

            // console.log(loanRequests.data.item2)
            groupLoanRequests.data.item2.forEach(item => {
                switch (item.currentLevel) {
                    case -1:
                    case 0:
                        item.requestStatus = "Created"
                        break;
                    case 100:
                        item.requestStatus = "DocumentGeneration"
                        break;
                    case 101:
                        item.requestStatus = "DocumentCollection"
                        break;
                    case 102:
                        item.requestStatus = "PreDisbursement"
                        break;
                    case 103:
                        item.requestStatus = "LoanDisbursement"
                        break;
                    default:
                        break;
                }
                if (item.currentLevel < 100 && item.currentLevel > 0) {
                    item.requestStatus = "LoanAppraisal"
                }
                item.loanType = "Group"
            })
            // console.log(groupLoanRequests.data.item2)
            if (this._isMounted) {
                this.setState({
                    tableItems: mapEntityList({
                        entityList: groupLoanRequests.data.item2,
                        properties: [
                            { oldName: "customerName", newName: "Name" },
                            { oldName: "phonenumber", newName: "phoneNumber" },
                            {
                                isTag: true,
                                oldName: "loanType",
                                newName: "loanType",
                                success: "Individual",
                                danger: "Inactive",
                                default: "default",
                                primary: "Corporate",
                                secondary: "secondary",
                                warning: "warning",
                                light: "light",
                                dark: "dark",
                            },
                            { oldName: "productName", newName: "productName" },
                            { oldName: "workFlowName", newName: "workFlow" },
                            { oldName: "levelName", newName: "appraisalStage" },
                            { oldName: "loanAmount", newName: "loanAmount" },
                            { oldName: "dateCreated", newName: "dateCreated" },
                            {
                                isTag: true,
                                oldName: "requestStatus",
                                newName: "requestStatus",
                                success: "Completed",
                                danger: "Rejected",
                                default: "default",
                                primary: "primary",
                                secondary: "secondary",
                                warning: "Created",
                                light: "light",
                                dark: "dark",
                            },
                        ],
                    }),
                    currentPage,
                    pageSize,
                    totalSize: groupLoanRequests.data.item1,
                    fetching: false,
                });
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                if (this._isMounted) {
                    this.setState({ fetching: false });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ fetching: false });
                }
            }
        } finally {
            if (this._isMounted) {
                this.setState({ fetching: false });
            }
        }
    };

    handleOnExport = async (type: string) => { };

    fetchItemById = async (item: any) => {
        if (this._isMounted) {
            this.setState({ fetchingDetail: true });
        }
        const loanRequest = await groupLoanRequestService.retrieveById(item.id);
        const loanRequestDetail = loanRequest.data;
        // console.log("fetched :: ", loanRequestDetail)
        // console.log("Json Data :: ", JSON.parse(JSON.parse(loanRequestDetail.jsonData)))
        // console.log("jsonLoanDetails :: ", JSON.parse(JSON.parse(JSON.parse(loanRequestDetail.jsonData)).LoanDetails))
        const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(loanRequestDetail.jsonData)).LoanDetails);
        // console.log(jsonLoanDetails)

        let preferredRepaymentBank: string = "";
        if (this._isMounted) {
            try {
                const response = await Promise.allSettled([
                    financialGroupService.retrieveFinancialGroupByProductCode(loanRequestDetail.productCode, (item.loanAmount / 100), loanRequestDetail.institutionCode),
                    loanProductService.retrieveByProductCode(loanRequestDetail.institutionCode, loanRequestDetail.productCode),
                    sweepConfigService.retrieveBankNameByCBNCode(jsonLoanDetails.PreferredRepaymentBankCBNCode)
                ])
                const financialGroup = response[0].status == "fulfilled" ? response[0].value.data : null;
                const loanProduct = response[1].status == "fulfilled" ? response[1].value.data : null;
                preferredRepaymentBank = response[2].status == "fulfilled" ? response[2].value.data : jsonLoanDetails.PreferredRepaymentBankCBNCode;

                if (financialGroup === null) {
                    // console.log("error caught")
                    this.setState({ showDetailsModal: false, fetchingDetail: false });
                    return;
                }

                loanRequestDetail["financialGroup"] = financialGroup;
                loanRequestDetail["loanProduct"] = loanProduct;
                loanRequestDetail["preferredRepaymentBank"] = preferredRepaymentBank;
                // loanRequestDetail.tenureInDays = jsonLoanDetails.LoanCycle
                if (this._isMounted) {
                    this.setState({ item: loanRequestDetail, fetchingDetail: false, showTable: false });
                    this.setState({ item: loanRequestDetail, fetchingDetail: false, showTable: false });
                }
            }
            catch (error) {
                this.setState({ showDetailsModal: false, fetchingDetail: false });
            }
        }
    };

    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    updateApprovalItemDocs = async (document: any) => {
        try {
            const docs = [...this.state.documentsToUpload];
            docs.push(document);
            this.setState({ documentsToUpload: docs, updateAvailable: true });
        } catch (error) {
            // log
        }
    };

    deleteApprovalItemDocs = async (document: any, documentId: number) => {
        try {
            if (document != null) {
                const docs = [...this.state.documentsToUpload];
                let selectedDoc = docs.filter(doc => doc.document === document.document && doc.name === document.name)[0]
                docs.splice(docs.indexOf(selectedDoc), 1);
                this.setState({ documentsToUpload: docs, updateAvailable: true });
            } else {
                const docs = [...this.state.documentsToDelete];
                docs.push(documentId);
                this.setState({ documentsToDelete: docs, updateAvailable: true });
            }
        } catch (error) {
            // log
        }
    };

    async componentDidMount() {
        this._isMounted = true;
        this.handlePageChange(this.state.currentPage, this.state.pageSize);
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        const response = await Promise.allSettled([
            rolesService.retrieveAllRoles(),
            appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
        ])
        const userRoles = response[0].status == "fulfilled" ? response[0].value.data : [];
        const appraisalInstitution = response[1].status == "fulfilled" ? response[1].value.data : [];
        const appraisalPlan = await appraisalPlanService.retrieveById(appraisalInstitution.planId);
        const reports = appraisalPlan.data.reports
        const validations = appraisalPlan.data.validations
        const systemProcess = [...reports, ...validations];
        if (this._isMounted) {
            this.setState({
                userRoles: userRoles,
                systemProcess: systemProcess
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleAppraisalProcess = () => {
        this.setState({
            showAppraisalProcess: !this.state.showAppraisalProcess,
            showDetailsModal: !this.state.showDetailsModal,
        });
    };

    toggleLoanDetails = () => {
        this.setState({
            showDetailsModal: !this.state.showDetailsModal,
            showAppraisalProcess: !this.state.showAppraisalProcess,
        });
    };

    render() {
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            item,
            fetchingDetail,
            showTable,
            userRoles,
            systemProcess
        } = this.state;

        return (
            <div className="container-fluid relative animatedParent animateOnce">
                <div className="animated fadeInUpShort go">
                    {/* {showTable === true ? ( */}
                    <div className="row my-3 mx-2">
                        <h3>
                            <b>Group Loan Requests</b>
                        </h3>
                    </div>
                    {/* ) : ("")} */}
                    <LoanRequestsList
                        pageChangeHandler={this.handlePageChange}
                        fetchingDetail={fetchingDetail}
                        initialValues={{
                            data: this.state.data,
                            fetching: false,
                            showDetailsModal: this.state.showDetailsModal,
                            showEditModal: false,
                            showTableFilterModal: false,
                            showTrailModal: false,
                            tableItems: [],
                            filterValueInput: this.state.filterValueInput,
                            item: {},
                        }}
                        tableItems={tableItems}
                        item={item}
                        DetailsModal={({ item, showDetailsModal, onToggleDetailsModal }) => (
                            <Fragment>
                                {!this.state.showAppraisalProcess ? (
                                    (() => {
                                        switch (item.currentLevel) {
                                            case 0:
                                                return (
                                                    <GroupAppraisalWorkflowModal
                                                        item={item}
                                                        userRoles={userRoles}
                                                        systemProcess={systemProcess}
                                                        showDetailsModal={showDetailsModal}
                                                        toggleDetailsModal={onToggleDetailsModal} />
                                                )
                                            // case 0:
                                            //     return (
                                            //         <DataValidationView
                                            //             item={item}
                                            //             // showAppraisalProcess={this.state.showAppraisalProcess}
                                            //             toggleAppraisalProcess={() => this.toggleAppraisalProcess()}
                                            //             // toggleLoanRequestDetails={this.toggleLoanDetails}
                                            //             reloadItem={() => { }}
                                            //         />
                                            //     )
                                            case 100:
                                                return (
                                                    <GroupOfferLetterScreen
                                                        values={item}
                                                    />
                                                )
                                            case 101:
                                                return (
                                                    <GroupDocumentCollection
                                                        values={item} />
                                                )
                                            case 102:
                                                return (
                                                    <PreDisbursement
                                                        item={item}
                                                        values={item}
                                                        userRoles={userRoles}
                                                        systemProcess={systemProcess}
                                                        updateItemDocs={(document: any) => this.updateApprovalItemDocs(document)}
                                                        deleteItemDocs={(document: any, documentId: number) => this.deleteApprovalItemDocs(document, documentId)}
                                                        tempDocs={this.state.documentsToUpload}
                                                        tempDeletedDocs={this.state.documentsToDelete}
                                                    />
                                                )
                                            case 103:
                                                return (
                                                    <LoanDisbursement
                                                        item={item}
                                                        values={item}
                                                        userRoles={userRoles}
                                                        systemProcess={systemProcess}
                                                        updateItemDocs={(document: any) => this.updateApprovalItemDocs(document)}
                                                        deleteItemDocs={(document: any, documentId: number) => this.deleteApprovalItemDocs(document, documentId)}
                                                        tempDocs={this.state.documentsToUpload}
                                                        tempDeletedDocs={this.state.documentsToDelete}
                                                    />
                                                )
                                            default:
                                                return (
                                                    <GroupAppraisalWorkflowModal
                                                        item={item}
                                                        userRoles={userRoles}
                                                        systemProcess={systemProcess}
                                                        showDetailsModal={showDetailsModal}
                                                        toggleDetailsModal={onToggleDetailsModal} />
                                                )
                                        }
                                    })()
                                ) : (
                                    <GroupAppraisalWorkflowModal
                                        item={item}
                                        userRoles={userRoles}
                                        systemProcess={systemProcess}
                                        showDetailsModal={showDetailsModal}
                                        toggleDetailsModal={onToggleDetailsModal} />
                                )}
                            </Fragment>

                        )}
                        ListPayLoad={({
                            data,
                            showTableFilterModal,
                            tableItems,
                            onToggleTableFilterModal,
                            onToggleDetailsModal,
                            onHandleFilterItemRemove,
                            filterValueInput,
                        }) => (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card no-b">
                                        {/* {showTable === true ? ( */}
                                        <div className="card-body">
                                            {/* {console.log(this.state.tableItems)} */}
                                            <div className="card-title">
                                                <div id="list-filters" className="ml-4 mr-4">
                                                    <TableFilterPane
                                                        filteredItems={this.getFilteredItems(data)}
                                                        handleFilterRemove={onHandleFilterItemRemove}
                                                    />
                                                </div>
                                                <div id="list-table">
                                                    <ItemsTable
                                                        data={data}
                                                        items={tableItems}
                                                        filterValueInput={filterValueInput}
                                                        showTableFiltermodal={showTableFilterModal}
                                                        toggleTableFiltermodal={onToggleTableFilterModal}
                                                        name="Group Loan Requests"
                                                        currentPage={currentPage}
                                                        pageSize={pageSize}
                                                        totalSize={totalSize}
                                                        fetching={fetching}
                                                        handlePageChange={this.handlePageChange}
                                                        onViewDetails={(item: any) => {
                                                            this.fetchItemById(item);
                                                            onToggleDetailsModal();
                                                        }}
                                                        handleOnExport={this.handleOnExport}
                                                        timeColumn={["dateCreated"]}
                                                        amountColumn={["loanAmount"]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ) : ("")} */}
                                    </div>
                                </div>
                            </div>
                        )}
                    ></LoanRequestsList>
                </div>
            </div >
        );
    }
}

export default ViewAllGroupLoanRequests;
