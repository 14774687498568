import http from "../httpService";
import { getCurrentUserSession } from "./authService";
import { status } from "../approval/approvableService";

interface IUpdateSystemSettings {
  enableMandatesByDefault: boolean;
  billMandateOnCreation: boolean;
  id: number;
  institutionId: number;
  status: status;
  dateCreated: Date;
}

const authBaseAddress = process.env.REACT_APP_AUTH_BASEADDRESS;
// export function saveSystemSettings(item) {
//   return http.post(
//     authBaseAddress +
//       `systemSettings/${getCurrentUserSession().sessionId}`,
//     item
//   );
// }

export function updateSystemSettings(item: IUpdateSystemSettings) {
  var url =
    authBaseAddress +
    `systemSettings/Update/${item.id}`;
  return http.post(url, item, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function generateNewApiToken() {
  var url =
    authBaseAddress +
    `systemSettings/GenerateNewAPIToken`;
  return http.get(url, {
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveApiToken() {
  var url =
    authBaseAddress +
    `systemSettings/RetrieveAPIToken`;
  return http.get(url, {
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function retrieveInstitutionSystemSettings(institutionId: number) {
  var url = authBaseAddress + `systemSettings/RetrieveByInstitutionId`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSystemSettingsByInstitutionId(institutionId: number) {
  var url = authBaseAddress + `systemSettings/RetrieveByInstitutionId/${institutionId}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveSystemSettingsById(id: number) {
  var url = authBaseAddress + `systemSettings/RetrieveById?id=${id}`;

  return http.get(url, {
    url: url,
    method: "get",
    headers: { Authorization: `Bearer ${getCurrentUserSession().token}` },
  });
}

export function retrieveUsersToReceiveSweepMails() {
  var url = authBaseAddress + `SystemSettings/RetrieveUsersToReceiveSweepMails`;
  return http.get(url, {
    url: url,
    method: "get",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function removeUserToReceiveSweepMails(userId) {
  var url = authBaseAddress + `SystemSettings/RemoveUserToReceiveSweepMails`;
  return http.post(url, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function addUserToReceiveSweepMails(userId) {
  var url = authBaseAddress + `SystemSettings/AddUserToReceiveSweepMails`;
  return http.post(url, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export function bulkUpdateMandateBillingStatusForInstitutions(data) {
  var url =
    authBaseAddress +
    `SystemSettings/bulkUpdateMandateBillingStatusForInstitutions`;
  return http.post(url, data, {
    url: url,
    method: "post",
    headers: {
      Authorization: `Bearer ${getCurrentUserSession().token}`,
    },
  });
}

export default {
  // saveSystemSettings,
  updateSystemSettings,
  retrieveInstitutionSystemSettings,
  retrieveSystemSettingsById,
  retrieveSystemSettingsByInstitutionId,
  generateNewApiToken,
  retrieveApiToken,
  retrieveUsersToReceiveSweepMails,
  removeUserToReceiveSweepMails,
  addUserToReceiveSweepMails,
  bulkUpdateMandateBillingStatusForInstitutions
};
