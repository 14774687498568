import http from "../httpService";
import { IFilterData } from "../approval/approvalItemService";

const appraisalBaseAddress = process.env.REACT_APP_HYBRIDAPPRAISAL_BASEADDRESS;

export function retrieveById(id) {
    return http.get(
        appraisalBaseAddress + `GroupLoanRequest/RetrieveById/${id}`
    );
}

export function retrieveAll() {
    return http.get(
        appraisalBaseAddress + `GroupLoanRequest/RetrieveAll`
    );
}

export function updateLoanRequest(payload) {
    return http.put(
        appraisalBaseAddress + `GroupLoanRequest/EditLoanRequest`,
        payload
    );
}

export function createLoanRequest(payload) {
    return http.post(
        appraisalBaseAddress + `GroupLoanRequest/CreateLoanRequest`,
        payload
    );
}

export function uploadMembers(payload) {
    return http.post(
        appraisalBaseAddress + `GroupLoanRequest/UploadMembers`,
        payload
    );
}

export function validateMemberRecord(payload) {
    return http.post(
        appraisalBaseAddress + `GroupLoanRequest/ValidateMemberRecords`,
        payload
    );
}

export function downloadBulkMembersTemplate(payload) {
    return http.get(
        appraisalBaseAddress + `GroupLoanRequest/DownloadBulkMembersTemplate`,
        payload
    );
}

export function reinitiateLoanRequests(id) {
    return http.post(
        appraisalBaseAddress + `GroupLoanRequest/ReinitiateLoanRequests/${id}`
    );
}

export function filterLoanRequests(
    payload: IFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        appraisalBaseAddress +
        `GroupLoanRequest/FilterLoanRequests?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export function filterRejectedLoanRequests(
    payload: IFilterData,
    maxSize: number,
    pageNum: number
) {
    payload = { ...payload };
    return http.post(
        appraisalBaseAddress +
        `GroupLoanRequest/FilterRejectedRequests?pageNum=${pageNum}&maxSize=${maxSize}`,
        payload
    );
}

export default {
    retrieveById,
    retrieveAll,
    updateLoanRequest,
    reinitiateLoanRequests,
    filterLoanRequests,
    filterRejectedLoanRequests,
    validateMemberRecord,
    uploadMembers,
    downloadBulkMembersTemplate
}