import React from 'react'
import _ from 'lodash'
import { Modal, Button } from 'react-bootstrap'
// import { Link } from "react-router-dom";
import authService, {
  saveUserSessionDetails
} from '../../services/auth/authService'
import activityTrailService from '../../services/auditTrail/activityTrailService'
import { toast } from 'react-toastify'
import usersService from '../../services/auth/usersService'

export interface TwoFaProps {
  showModal: boolean
  currentUser: any
  toggle2FA: any
  userId: number
  username: string
  password: string
}

export interface TwoFaState {
  data: {
    token: string
  }
  submitting: boolean
  errors: string
}

interface TwoFaFields {
  token: string
}

class TwoFa extends React.Component<TwoFaProps, TwoFaState> {
  constructor (props: TwoFaProps) {
    super(props)
    this.state = {
      data: {
        token: ''
      },
      errors: '',
      submitting: false
    }
  }

  handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...this.state.data }
    if (_.isEmpty(e.currentTarget.min)) {
      // console.log("no min value");
      // console.log("testing: ", e.currentTarget.min);
      data[e.currentTarget.name] = e.currentTarget.value
      this.setState({ data })
    } else {
      if (e.currentTarget.value >= e.currentTarget.min) {
        data[e.currentTarget.name] = e.currentTarget.value
        this.setState({ data })
      }
    }
  }

  handleOnSubmit = async () => {
    if (this.state?.data.token === '') {
      return
    }

    const { currentUser, userId, username, password } = this.props
    const { data } = this.state
    try {
      this.setState({ submitting: true })
      // console.log('the currentuser: ', currentUser)
      // let user = await usersService.retrieveUserById(currentUser.userId);
      const payload: any = {}
      payload.institutionCode = currentUser.institution.code
      payload.userName = username
      payload.password = password
      payload.token = data.token
      let response = await authService.loginUser(payload)
      //console.log('2fa verify: ', response)
      // console.log("user:: ", currentUser);
      // console.log("token:: ", data.token);
      if (response.status >= 200 && response.status <= 300) {
        await authService.setRoleFunctionsForUser(
          response.data.userId,
          response.data.token
        )
        await saveUserSessionDetails(response.data)
        await activityTrailService.saveActivityTrail({
          actionType: 'Login',
          description: `User Logged In`
        })

        this.props.toggle2FA(true, response.data)
        this.setState({ submitting: false })
        return
      } else {
        toast.error(`Invalid Token`, {
          autoClose: 6000,
          hideProgressBar: false
        })
        this.setState({ submitting: false })
        return
      }
    } catch (ex) {
      this.setState({ submitting: false })
      toast.error(`Something went wrong. Please, try again`, {
        autoClose: 6000,
        hideProgressBar: false
      })
      return
    }
  }

  render () {
    const { data, submitting, errors } = this.state
    const { showModal } = this.props
    return (
      <Modal backdrop='static' show={showModal}>
        <form action='#'>
          <div className='modal-header text-center'>
            <h4 className='modal-title w-100'>
              <b>Two-Factor Authentication</b>
            </h4>
          </div>
          <Modal.Body>
            <div className='text-center'>
              <i className='fas fa-user-circle fa-6x' />
              <br />
              <br />
              {/* <h4>
                <b>
                  {_.isEmpty(currentUser)
                    ? ""
                    : `${currentUser.firstName} ${currentUser.lastName}`}
                </b>
              </h4> */}
              <h6 style={{ color: 'red' }}>Please enter your 2FA Token</h6>
            </div>
            <div className='modal-body mx-3'>
              <input
                type='password'
                style={{ textAlign: 'center' }}
                id='token'
                value={data.token}
                name='token'
                className='form-control r-0 light s-12 mb-4'
                onChange={this.handleOnInputChange}
              />
            </div>
            <h6 className='text-center'>
              <i style={{ color: 'red' }}>{errors}</i>
            </h6>
            <br />
            <div className='text-center'>
              <Button
                size='sm'
                type='submit'
                variant='primary'
                onClick={e => {
                  e.preventDefault()
                  this.handleOnSubmit()
                }}
              >
                {submitting === false ? (
                  <React.Fragment>
                    <i className='fas fa-unlock-alt mr-2'></i>Unlock
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i className='fas fa-spin fa-circle-notch' />
                  </React.Fragment>
                )}
              </Button>
            </div>
          </Modal.Body>
          <div
            className='modal-footer justify-content-center'
            onClick={() => {
              this.props.toggle2FA(false, this.props.currentUser)
            }}
            style={{ cursor: 'pointer' }}
          >
            <i className='fas fa-sign-in-alt ml-4 mr-2' />
            Back To Login
          </div>
        </form>
      </Modal>
    )
  }
}

export default TwoFa
