import React, { Component, Fragment, useContext } from "react";
import {
    Modal,
    Button,
    Card,
    useAccordionToggle,
    AccordionContext,
    Accordion,
} from "react-bootstrap";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import BaseFormComponent, { IBaseFormState } from "../../../../components/BaseFormComponent";
import textFormatService from "../../../../services/utility/textFormatService";
import ItemsTable, { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession } from "../../../../services/auth/authService";
// import TinRequestDetails from "../../../../loanAppraisal/appraisalDetailPages/TinRequestDetails";
// import { ContextAwareToggle } from "./documentCollection";
import { decodeToken } from "react-jwt";
import BaseListComponent, { IFilterData } from "../../../../components/BaseListComponent";
import loanProductService from "../../../../services/hybridAppraisal/loanProductService";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import TinValidationListModal from "./tinValidationList";
import PolicyScoringListModal from "./policyScroingList";
import FinancialAnalysisListModal from "./financialAnalysisList";

interface FinancialAnalysisFields { }

class FinancialAnalysisForm extends BaseFormComponent<FinancialAnalysisFields> { }

interface IFinancialAnalysisModalProps {
    item: any;
    userRoles: any;
    systemProcess: any;
    currentUserSession: any;
}

interface IFinancialAnalysisModalState {
    item: any;
    updatingStatus: boolean;
    submitting: boolean;
    data: IFilterData;
    currentPage: number;
    pageSize: number;
    tableItems: any;
    fetching: boolean;
    fetchingDetail: boolean;
    totalSize: number;
    filterValueInput: filterFieldData;
    userRoles: any;
    systemProcess: any;
    applicableRules: any;
    appraisalActions: any;
    updateComponent: boolean;
    documentsToUpload: any;
    documentsToDelete: number[];
    canEditItem: boolean;
    updateAvailable: boolean;
    processResponse: any;
    specialConditions: any;
    ownUpdate: boolean;
    showPersonalDetails: boolean;
}

class FinancialAnalysisList extends BaseListComponent<IFinancialAnalysisModalState> { }

class FinancialAnalysis extends React.Component<
    IFinancialAnalysisModalProps,
    IBaseFormState & IFinancialAnalysisModalState
> {
    _isMounted: boolean;
    constructor(props: IFinancialAnalysisModalProps) {
        super(props);
        this.state = {
            validated: false,
            submitting: false,
            errors: {},
            item: {},
            updatingStatus: false,
            data: createFilterData(["customerName", "loanType"]) as IFilterData,
            currentPage: 1,
            pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
            fetching: false,
            fetchingDetail: false,
            tableItems: [],
            totalSize: 0,
            filterValueInput: [
                { name: "customerName", fieldType: "text", value: "" },
                {
                    name: "loanType",
                    fieldType: "select",
                    options: ["Individual", "Corporate"],
                    value: "",
                }
            ],
            userRoles: [],
            systemProcess: [],
            applicableRules: [],
            appraisalActions: [],
            documentsToUpload: [],
            documentsToDelete: [],
            updateComponent: false,
            canEditItem: false,
            updateAvailable: false,
            specialConditions: [],
            processResponse: {
                actionId: 0,
                score: 0,
                response: ""
            },
            ownUpdate: false,
            showPersonalDetails: false
        };
    }

    resetFields() {
        this.setState({
            validated: false,
            submitting: false
        });
    }

    handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
        currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
        pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
        if (this._isMounted) {
            this.setState({ fetching: true, ownUpdate: true });
        }
        let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        dataClone["InstitutionCode"] = { item1: "=", item2: decodedToken?.["InstitutionCode"] }
        dataClone["TIN"] = { item1: "=", item2: this.props.item.tin }
        try {
            // console.log("tin", this.props.item)
            // console.log("tin", this.props.item.tin)
            // const loanRequests = await loanRequestService.retrieveAllByTin(this.props.item.tin, decodedToken?.["InstitutionCode"])
            const response = await Promise.allSettled([
                loanRequestService.filterLoanRequests(dataClone, pageSize, currentPage),
                loanProductService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
            ])
            // console.log(response)
            const loanRequests = response[0].status == "fulfilled" ? response[0].value.data : [];
            const loanProducts = response[1].status == "fulfilled" ? response[1].value.data : [];

            // console.log(this.state.item)
            const requests = loanRequests.item2;
            requests.splice(
                requests.indexOf(requests.filter(request => request.id == this.state.item.id)[0]), 1
            );

            // console.log("request :: ", requests)
            // console.log("products :: ", loanProducts)
            requests.forEach(request => {
                request["productName"] = loanProducts.filter(product => product.productCode == request.productCode)[0].name;
            });
            // console.log("item index :: ", requests.filter(request => request.id == this.state.item.id))
            // console.log("index :: ", requests.indexOf(requests.filter(request => request.id == this.state.item.id)[0]))
            // console.log("request :: ", requests)
            // console.log("request :: ", loanRequests.data)
            if (this._isMounted) {
                this.setState({
                    tableItems: mapEntityList({
                        entityList: requests,
                        properties: [
                            // { oldName: "customerName", newName: "Name" },
                            // { oldName: "phonenumber", newName: "phoneNumber" },
                            // { oldName: "loanAmount", newName: "loanAmount" },
                            // { oldName: "tenureInDays", newName: "tenor" },
                            // { oldName: "branch", newName: "branch" },
                            // { oldName: "productName", newName: "productName" },
                            // { oldName: "dateCreated", newName: "dateCreated" },

                            { oldName: "noOfCustomers", newName: "totalNoOfCustomers" },
                            { oldName: "matchedCustomers", newName: "matchedCustomers" },
                            { oldName: "noMatchCustomers", newName: "noMatchCustomers" },
                            // {
                            //     isTag: true,
                            //     oldName: "requestStatus",
                            //     newName: "status",
                            //     success: "Active",
                            //     danger: "Rejected",
                            //     default: "default",
                            //     primary: "primary",
                            //     secondary: "secondary",
                            //     warning: "Created",
                            //     light: "light",
                            //     dark: "dark",
                            // },
                        ],
                    }),
                    currentPage,
                    pageSize,
                    totalSize: loanRequests.item1 - 1,
                    fetching: false,
                    ownUpdate: true
                });
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ fetching: false, ownUpdate: true });
                }
            }
        } finally {
            if (this._isMounted) {
                this.setState({ fetching: false, ownUpdate: true });
            }
        }
    };
    getFilteredItems = (data: IFilterData): IFilterData => {
        let filteredItems: IFilterData = {};
        Object.keys(data).forEach((key) => {
            if (data[key].item2 !== "") {
                filteredItems[key] = data[key];
            }
        });
        return filteredItems;
    };

    toggleShowPersonalDetailsModal = () => {
        this.setState({ showPersonalDetails: !this.state.showPersonalDetails });
    };

    handleOnExport = async (type: string) => { };

    render() {
        const { item, userRoles, systemProcess, currentUserSession } = this.props;
        const {
            currentPage,
            pageSize,
            tableItems,
            totalSize,
            fetching,
            fetchingDetail,
            submitting
        } = this.state;

        if (_.isEmpty(item)) {
            return null;
        }

        return (

            <><FinancialAnalysisForm
                initialValues={{}}
                FormComponent={({
                    fields: { }, onChange, onReloadFields, onHandleSubmit,
                }) => (
                    <React.Fragment>
                        <div className="card">
                            <div className="card-header clear-fix">
                                {_.isEmpty(item) ? (
                                    <Skeleton width={200} />
                                ) : (
                                    <h6
                                        className="card-title float-left"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <b>Financial Analysis</b>
                                    </h6>
                                )}
                            </div>
                            <div className="card">
                                <Accordion defaultActiveKey="0">
                                    <div>
                                        <Card>
                                            {/* <ContextAwareToggle
                            eventKey={`0`}
                            level={""}
                        ></ContextAwareToggle> */}
                                            <Accordion.Collapse eventKey={`0`}>
                                                <Card.Body>
                                                    <FinancialAnalysisList
                                                        pageChangeHandler={this.handlePageChange}
                                                        fetchingDetail={fetchingDetail}
                                                        initialValues={{
                                                            data: this.state.data,
                                                            fetching: false,
                                                            showDetailsModal: false,
                                                            showEditModal: false,
                                                            showTableFilterModal: false,
                                                            showTrailModal: false,
                                                            tableItems: [],
                                                            filterValueInput: this.state.filterValueInput,
                                                            item: {},
                                                        }}
                                                        tableItems={tableItems}
                                                        item={item}
                                                        DetailsModal={({
                                                            item, onToggleDetailsModal, onToggleEditModal, showDetailsModal, showEditModal
                                                        }) => (
                                                            <Fragment>
                                                                <FinancialAnalysisListModal
                                                                    item={this.state.item}
                                                                    showPatchModal={this.state.showPersonalDetails}
                                                                    togglePatchModal={this.toggleShowPersonalDetailsModal} userRoles={userRoles} systemProcess={systemProcess} currentUserSession={currentUserSession} />
                                                            </Fragment>

                                                        )}
                                                        ListPayLoad={({
                                                            data, showTableFilterModal, tableItems, onToggleDetailsModal, onToggleTableFilterModal, onHandleFilterItemRemove, filterValueInput,
                                                        }) => (
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="card no-b">
                                                                        <div className="card-body">
                                                                            <div className="card-title">
                                                                                <div id="list-filters" className="ml-4 mr-4">
                                                                                    <TableFilterPane
                                                                                        filteredItems={this.getFilteredItems(data)}
                                                                                        handleFilterRemove={onHandleFilterItemRemove} />
                                                                                </div>
                                                                                <div id="list-table">
                                                                                    <ItemsTable
                                                                                        data={data}
                                                                                        items={tableItems}
                                                                                        filterValueInput={filterValueInput}
                                                                                        showTableFiltermodal={showTableFilterModal}
                                                                                        toggleTableFiltermodal={onToggleTableFilterModal}
                                                                                        name="Tin Validations"
                                                                                        currentPage={currentPage}
                                                                                        pageSize={pageSize}
                                                                                        totalSize={totalSize}
                                                                                        fetching={fetching}
                                                                                        handlePageChange={this.handlePageChange}
                                                                                        onViewDetails={(item: any) => {
                                                                                            onToggleDetailsModal();
                                                                                        }}
                                                                                        handleOnExport={this.handleOnExport}
                                                                                        timeColumn={["dateCreated"]}
                                                                                        amountColumn={["loanAmount"]} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    ></FinancialAnalysisList>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </div>
                                </Accordion>
                            </div>
                        </div>

                    </React.Fragment>
                )}
            ></FinancialAnalysisForm>
            </>
        );
    }
}

export default FinancialAnalysis;
