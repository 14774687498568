import React, { Component } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import systemSettingsService from "../../services/auth/systemSettingsService";
import usersService from "../../services/auth/usersService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../services/auth/authService";
import { Button } from "react-bootstrap";
import activityTrailService from "../../services/auditTrail/activityTrailService";
import MailListModal from "./mailListModal";
import { decodeToken } from "react-jwt";
import savingsCurrentProductService from "../../services/auth/savingsCurrentProductService";
import institutionsService from "../../services/auth/institutionsService";
import SweepInitiationConfigModal from "./sweepInitiationConfigModal";
// import FormInputComponent from "../../components/formInputComponent";

interface ISystemSettingsProps {}
interface ISystemSettingsState {
    sweepMailsList: any;
    products: any;
    settings: any;
    apiToken: string;
    fetching: boolean;
    users: any;
    minimumGuaranteeingPercentage: number;
    minimumGuaranteeingPercentagePerGuarantor: number;
    noOfDaysPastDue: number;
    disbursementReceivableGl: string;
    showMailListModal: boolean;
    showSweepInitConfigModal: boolean;
    defaultSavingsCurrentProductCode: string;
    institution: any;
}

class SystemSettings extends Component<ISystemSettingsProps, ISystemSettingsState> {
    constructor(props: ISystemSettingsProps) {
        super(props);
        this.state = {
            sweepMailsList: [],
            products: [],
            settings: {},
            apiToken: "",
            fetching: true,
            users: [],
            showMailListModal: false,
            showSweepInitConfigModal: false,
            minimumGuaranteeingPercentage: 0,
            minimumGuaranteeingPercentagePerGuarantor: 0,
            noOfDaysPastDue: 60,
            disbursementReceivableGl: "",
            defaultSavingsCurrentProductCode: "",
            institution: {},
        };
    }

    async componentDidMount() {
        try {
            const decodedToken = decodeToken(getCurrentUserSession().token || "");
            const settings = await systemSettingsService.retrieveInstitutionSystemSettings(decodedToken?.["InstitutionId"] || 0);
            const casaproducts = await savingsCurrentProductService.retrieveAllBankOneSavingsCurrentProducts(decodedToken?.["InstitutionId"] || 0);
            const sweepmailusers = await systemSettingsService.retrieveUsersToReceiveSweepMails();
            const users = await usersService.retrieveAll();
            const institution = await institutionsService.retrieveInstitutionById(decodedToken?.["InstitutionId"] || 0);
            // console.log("settings: ", settings.data);
            // console.log("institution: ", institution.data);
            // console.log("users: ", users.data);
            // var token = await systemSettingsService.retrieveApiToken();
            this.setState({
                settings: settings.data,
                users: users.data,
                institution: institution.data,
                sweepMailsList: sweepmailusers.data,
                fetching: false,
                minimumGuaranteeingPercentage: settings.data.minimumGuaranteeingPercentage,
                minimumGuaranteeingPercentagePerGuarantor: settings.data.minimumGuaranteeingPercentagePerGuarantor,
                noOfDaysPastDue: settings.data.noOfDaysPastDue,
                disbursementReceivableGl: settings.data.disbursementReceivableGl,
                defaultSavingsCurrentProductCode: settings.data.defaultSavingsCurrentProductCode,
                products: casaproducts.data ? casaproducts.data : [],
            });
            // console.log("first check :: ", casaproducts)
            // console.log("second check :: ", casaproducts.data)
        } catch (ex) {
            this.setState({ fetching: false });
        }
    }

    handleOnDeleteUserMailList = async (userId) => {
        await systemSettingsService.removeUserToReceiveSweepMails(userId);
        const sweepmailusers = await systemSettingsService.retrieveUsersToReceiveSweepMails();
        this.setState({ sweepMailsList: sweepmailusers.data });
    };
    toggleMailListModal = () => {
        this.setState({ showMailListModal: !this.state.showMailListModal });
        // if (this.state.showModal === false) {
        //   this.fetchItemByCode(institutionCode);
        // } else {
        //   this.setState({ sweepStatus: {} });
        // }
    };
    toggleSweepInitConfigModal = () => {
        this.setState({ showSweepInitConfigModal: !this.state.showSweepInitConfigModal });
    };

    handleOnAddSweepMailingList = async (userId) => {
        await systemSettingsService.addUserToReceiveSweepMails(userId);
        const sweepmailusers = await systemSettingsService.retrieveUsersToReceiveSweepMails();
        this.setState({ sweepMailsList: sweepmailusers.data });
        this.toggleMailListModal();
    };

    handleAPIDocumentation = () => {
        if (this.state.institution.category == "CommercialBank") {
            window.open("https://documenter.getpostman.com/view/8529118/Uyr4KfMt", "_blank");
        } else if (this.state.institution.category == "OFI") {
            window.open("https://documenter.getpostman.com/view/520368/Uz5DqHGJ", "_blank");
        }
    };

    handleOnGenerateAPIToken = async (e) => {
        const decodedToken = decodeToken(getCurrentUserSession().token || "");
        try {
            swal({
                title: "Confirm!",
                text: "Are you sure you want to generate a new API Token? ",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Yes",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    cancel: {
                        text: "Cancel",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                },
                closeOnClickOutside: false,
                dangerMode: true,
            }).then(async (resp) => {
                if (resp) {
                    //   console.log("cancelling action...");
                } else {
                    var response = await systemSettingsService.generateNewApiToken();
                    await activityTrailService.saveActivityTrail({
                        actionType: "Generate API Token",
                        description: `A new API Token was generated. [${decodedToken?.["UserId"]}]`,
                    });

                    // console.log("response: ", response);
                    this.setState({ apiToken: response.data });
                    swal({
                        title: "Token",
                        text: `Bearer ${response.data}`,
                        icon: "success",
                    });
                    toast.info(`All requests to Recova APIs would be authenticated with this token. Please ensure it is kept secret. `, {
                        autoClose: false,
                        type: toast.TYPE.WARNING,
                        hideProgressBar: false,
                    });
                    //   this.submit({ actionType: actionType, entityName: entityName });
                }
            });
        } catch (ex) {}
    };

    onChange(e): void {
        const settings = { ...this.state.settings };
        settings[e.currentTarget.id] = e.currentTarget.value;
        this.setState({ settings });
    }

    onSelectChange(e): void {
        const settings = { ...this.state.settings };
        settings[e.currentTarget.id] = e.currentTarget.value;
        this.setState({ settings });
    }

    onBlur = async (e) => {
        const settings = { ...this.state.settings };
        var response = await systemSettingsService.updateSystemSettings(settings);
        this.setState({ settings: response.data.payLoad });
    };

    handleEnableOrDisableSystemSettings = async (e) => {
        try {
            const settings = { ...this.state.settings };
            settings[`${e.currentTarget.name}`] = !settings[`${e.currentTarget.name}`];
            var response = await systemSettingsService.updateSystemSettings(settings);
            this.setState({ settings: response.data.payLoad });
        } catch (ex) {}
    };

    render() {
        const { settings, fetching, products, apiToken, institution, sweepMailsList } = this.state;
        return (
            <div className="container-fluid relative animatedParent animateOnce">
                <div className="animated fadeInUpShort go">
                    <div className="row my-3 mx-2">
                        <h3>
                            <b>System Settings</b>
                        </h3>
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ padding: "0px", height: "35px", paddingTop: "5px" }}>
                            <div className="clearfix">
                                <div className="float-left">
                                    <i className="fas fa-cogs mr-3 ml-2"></i> General System Settings
                                </div>
                            </div>
                        </div>
                        <div className="card-body collapse show" id="collapseGeneralSettings">
                            <div className="card-content">
                                {fetching === true ? (
                                    <Skeleton height={50} count={8} />
                                ) : (
                                    <React.Fragment>
                                        <div
                                            className="row border"
                                            style={{
                                                padding: "0px 100px",
                                                margin: "0px 2px",
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-md-8 col-sm-8">
                                                <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                    API Token
                                                </h6>
                                                <span className="font-italic">
                                                    <p
                                                        className="deep-orange-text"
                                                        style={{
                                                            paddingBottom: "0px",
                                                            marginBottom: "0px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        API Token for External Institutions to authorize all requests to Recova EndPoints.
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="col-md-2 col-sm-2 mt-3">
                                                {getCurrentUsersRoles().includes("generateapitoken") && (
                                                    <Button
                                                        type="button"
                                                        variant="outline-secondary"
                                                        onClick={(e) => this.handleOnGenerateAPIToken(e)}
                                                        className="btn-copy-code btn-sm px-2 waves-effect"
                                                    >
                                                        <i className="fa fa-copy mr-1"></i> Re-Generate
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                        {institution.category !== "BankOneOFI" && (
                                            <div
                                                className="row border"
                                                style={{
                                                    padding: "10px 100px",
                                                    margin: "0px 2px",
                                                    backgroundColor: "#FFFFFF",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <div className="col-md-8 col-sm-8">
                                                    <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                        API Documentation
                                                    </h6>
                                                    <span className="font-italic">
                                                        <p
                                                            className="deep-orange-text"
                                                            style={{
                                                                paddingBottom: "0px",
                                                                marginBottom: "0px",
                                                                fontSize: "15px",
                                                                lineHeight: "20px",
                                                            }}
                                                        >
                                                            Recova Direct Debit and Repayment API Documentation
                                                        </p>
                                                    </span>
                                                </div>
                                                <div className="col-md-2 col-sm-2 mt-3">
                                                    {getCurrentUsersRoles().includes("generateapitoken") && (
                                                        <Button
                                                            type="button"
                                                            variant="outline-secondary"
                                                            onClick={(e) => this.handleAPIDocumentation()}
                                                            className="btn-copy-code btn-sm px-2 waves-effect"
                                                        >
                                                            <i className="fa fa-copy mr-1"></i> View Documentation
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {/* <div
                      className="row border"
                      style={{
                        padding: "0px 100px",
                        margin: "0px 2px",
                        backgroundColor:
                          settings.enableMandatesByDefault === true
                            ? "#FFFFFF"
                            : "#F6F7F9",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="col-md-8 col-sm-8">
                        <h6
                          className={
                            settings.enableMandatesByDefault === true
                              ? "font-weight-normal"
                              : "font-weight-light"
                          }
                          style={{ marginTop: "5px", marginBottom: "0px" }}
                        >
                          Enable Mandates By Default
                        </h6>
                        <span className="font-italic">
                          <p
                            className={
                              settings.enableMandatesByDefault === true
                                ? "deep-orange-text"
                                : "text-muted"
                            }
                            //className="deep-orange-text"
                            style={{
                              paddingBottom: "0px",
                              marginBottom: "0px",
                              fontSize: "15px",
                              lineHeight: "20px"
                            }}
                          >
                            All newly created mandates should be{" "}
                            <strong>Active</strong> when created.
                          </p>
                        </span>
                      </div>
                      <div className="col-md-2 col-sm-2">
                        <div className="form-group row">
                          <div
                            className="col-md-8 material-switch float-right"
                            style={{
                              paddingLeft: "3.25rem",
                              paddingTop: "3rem",
                            }}
                          >
                            <input
                              id="enableMandatesByDefault"
                              name="enableMandatesByDefault"
                              type="checkbox"
                              onChange={(e) =>
                                this.handleEnableOrDisableSystemSettings(e)
                              }
                              defaultChecked={
                                settings.enableMandatesByDefault === true
                              }
                            />
                            <label
                              htmlFor="enableMandatesByDefault"
                              className="bg-info"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                     */}
                                        <div
                                            className="row border"
                                            style={{
                                                padding: "0px 100px",
                                                margin: "0px 2px",
                                                backgroundColor: settings.validateEmailDomain === true ? "#FFFFFF" : "#F6F7F9",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-md-8 col-sm-8">
                                                <h6
                                                    className={settings.validateEmailDomain === true ? "font-weight-normal" : "font-weight-light"}
                                                    style={{ marginTop: "5px", marginBottom: "0px" }}
                                                >
                                                    Validate Email Domain
                                                </h6>
                                                <span className="font-italic">
                                                    <p
                                                        className={settings.validateEmailDomain === true ? "deep-orange-text" : "text-muted"}
                                                        //className="deep-orange-text"
                                                        style={{
                                                            paddingBottom: "0px",
                                                            marginBottom: "0px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        Validate the email domain of users that are to be created in the Institution.
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="col-md-2 col-sm-2">
                                                <div className="form-group row">
                                                    <div
                                                        className="col-md-8 material-switch float-right"
                                                        style={{
                                                            paddingLeft: "3.25rem",
                                                            paddingTop: "3rem",
                                                        }}
                                                    >
                                                        <input
                                                            id="validateEmailDomain"
                                                            name="validateEmailDomain"
                                                            type="checkbox"
                                                            onChange={(e) => this.handleEnableOrDisableSystemSettings(e)}
                                                            defaultChecked={settings.validateEmailDomain === true}
                                                        />
                                                        <label htmlFor="validateEmailDomain" className="bg-info"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {institution.category === "BankOneOFI" ? (
                                            <>
                                                <div
                                                    className="row border"
                                                    style={{
                                                        padding: "0px 100px",
                                                        margin: "0px 2px",
                                                        backgroundColor: settings.chargeCustomerForFundsTransfer === true ? "#FFFFFF" : "#F6F7F9",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <div className="col-md-8 col-sm-8">
                                                        <h6
                                                            className={
                                                                settings.chargeCustomerForFundsTransfer === true
                                                                    ? "font-weight-normal"
                                                                    : "font-weight-light"
                                                            }
                                                            style={{ marginTop: "5px", marginBottom: "0px" }}
                                                        >
                                                            Charge Customer For Funds Transfer
                                                        </h6>
                                                        <span className="font-italic">
                                                            <p
                                                                className={
                                                                    settings.chargeCustomerForFundsTransfer === true
                                                                        ? "deep-orange-text"
                                                                        : "text-muted"
                                                                }
                                                                //className="deep-orange-text"
                                                                style={{
                                                                    paddingBottom: "0px",
                                                                    marginBottom: "0px",
                                                                    fontSize: "15px",
                                                                    lineHeight: "20px",
                                                                }}
                                                            >
                                                                Determine whether the customer or the institution would bear the charge for funds
                                                                transfer after loan disbursement
                                                            </p>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-2 col-sm-2">
                                                        <div className="form-group row">
                                                            <div
                                                                className="col-md-8 material-switch float-right"
                                                                style={{
                                                                    paddingLeft: "3.25rem",
                                                                    paddingTop: "3rem",
                                                                }}
                                                            >
                                                                <input
                                                                    id="chargeCustomerForFundsTransfer"
                                                                    name="chargeCustomerForFundsTransfer"
                                                                    type="checkbox"
                                                                    onChange={(e) => this.handleEnableOrDisableSystemSettings(e)}
                                                                    defaultChecked={settings.chargeCustomerForFundsTransfer === true}
                                                                />
                                                                <label htmlFor="chargeCustomerForFundsTransfer" className="bg-info"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row border"
                                                    style={{
                                                        padding: "0px 100px",
                                                        margin: "0px 2px",
                                                        backgroundColor: "#FFFFFF",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <div className="col-md-8 col-sm-8">
                                                        <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                            Disbursement Receiveable GL
                                                        </h6>
                                                        <span className="font-italic">
                                                            <p
                                                                className="deep-orange-text"
                                                                //className="deep-orange-text"
                                                                style={{
                                                                    paddingBottom: "0px",
                                                                    marginBottom: "0px",
                                                                    fontSize: "15px",
                                                                    lineHeight: "20px",
                                                                }}
                                                            >
                                                                This refers to the GL where funds would be transfered to after credit assessment loan
                                                                disbursement.
                                                            </p>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-2 col-sm-2 mt-3">
                                                        <input
                                                            required={false}
                                                            id="disbursementReceivableGl"
                                                            name={"disbursementReceivableGl"}
                                                            // placeholder={placeholder}
                                                            className="form-control r-0 light s-12"
                                                            type="number"
                                                            value={
                                                                this.state.settings.disbursementReceivableGl === null
                                                                    ? ""
                                                                    : this.state.settings.disbursementReceivableGl
                                                            }
                                                            disabled={false}
                                                            onChange={(e) => this.onChange(e)}
                                                            onBlur={(e) => this.onBlur(e)}
                                                            // onBlur={onBlur ? () => onBlur(value) : onBlur}
                                                        />
                                                        {/* {getCurrentUsersRoles().includes(
                          "generateapitoken"
                        ) && (
                         
                        )} */}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {institution.category === "BankOneOFI" ? (
                                            <div
                                                className="row border"
                                                style={{
                                                    padding: "0px 100px",
                                                    margin: "0px 2px",
                                                    backgroundColor: "#FFFFFF",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <div className="col-md-8 col-sm-8">
                                                    <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                        Default Savings Current Product Code
                                                    </h6>
                                                    <span className="font-italic">
                                                        <p
                                                            className="deep-orange-text"
                                                            //className="deep-orange-text"
                                                            style={{
                                                                paddingBottom: "0px",
                                                                marginBottom: "0px",
                                                                fontSize: "15px",
                                                                lineHeight: "20px",
                                                            }}
                                                        >
                                                            This refers to the default Savings and Current Product Code that would be used in creating
                                                            new Customers and Accounts if any need for tha arises.
                                                        </p>
                                                    </span>
                                                </div>
                                                <div className="col-md-2 col-sm-2 mt-3">
                                                    <select
                                                        id="defaultSavingsCurrentProductCode"
                                                        name={"defaultSavingsCurrentProductCode"}
                                                        className={"custom-select select2 mr-sm-2 form-control r-0 light s-12"}
                                                        // required={required}
                                                        value={
                                                            this.state.settings.defaultSavingsCurrentProductCode === null
                                                                ? ""
                                                                : this.state.settings.defaultSavingsCurrentProductCode
                                                        }
                                                        onChange={(e) => this.onSelectChange(e)}
                                                        onBlur={(e) => this.onBlur(e)}
                                                    >
                                                        <option value="">{"-Select Default CASA Product-"}</option>
                                                        {products.map((product) => (
                                                            <option key={product.productId} value={product.code}>
                                                                {product.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div
                                            className="row border"
                                            style={{
                                                padding: "0px 100px",
                                                margin: "0px 2px",
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-md-8 col-sm-8">
                                                <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                    Minimum No. Of Days Past Due For Defaulting Loans
                                                </h6>
                                                <span className="font-italic">
                                                    <p
                                                        className="deep-orange-text"
                                                        //className="deep-orange-text"
                                                        style={{
                                                            paddingBottom: "0px",
                                                            marginBottom: "0px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        This refers to the minimum number of Days Past Due i.e days of default to filter by when
                                                        retrieving defaulting loans from FIs.
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="col-md-2 col-sm-2 mt-3">
                                                <input
                                                    required={false}
                                                    id="noOfDaysPastDue"
                                                    name={"noOfDaysPastDue"}
                                                    // placeholder={placeholder}
                                                    className="form-control r-0 light s-12"
                                                    type="number"
                                                    value={this.state.settings.noOfDaysPastDue}
                                                    disabled={false}
                                                    onChange={(e) => this.onChange(e)}
                                                    onBlur={(e) => this.onBlur(e)}
                                                    // onBlur={onBlur ? () => onBlur(value) : onBlur}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="row border"
                                            style={{
                                                padding: "0px 100px",
                                                margin: "0px 2px",
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-md-8 col-sm-8">
                                                <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                    Minimum Guaranteeing Percentage
                                                </h6>
                                                <span className="font-italic">
                                                    <p
                                                        className="deep-orange-text"
                                                        //className="deep-orange-text"
                                                        style={{
                                                            paddingBottom: "0px",
                                                            marginBottom: "0px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        This refers to the minimum percentage of the Loan that has to be guaranteed by all the
                                                        guarantor(s) before guaranteed mandates can be created.
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="col-md-2 col-sm-2 mt-3">
                                                <input
                                                    required={false}
                                                    id="minimumGuaranteeingPercentage"
                                                    name={"minimumGuaranteeingPercentage"}
                                                    // placeholder={placeholder}
                                                    className="form-control r-0 light s-12"
                                                    type="number"
                                                    value={this.state.settings.minimumGuaranteeingPercentage}
                                                    disabled={false}
                                                    onChange={(e) => this.onChange(e)}
                                                    onBlur={(e) => this.onBlur(e)}
                                                    // onBlur={onBlur ? () => onBlur(value) : onBlur}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="row border"
                                            style={{
                                                padding: "0px 100px",
                                                margin: "0px 2px",
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-md-8 col-sm-8">
                                                <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                    Minimum Guaranteeing Percentage Per Guarantor
                                                </h6>
                                                <span className="font-italic">
                                                    <p
                                                        className="deep-orange-text"
                                                        //className="deep-orange-text"
                                                        style={{
                                                            paddingBottom: "0px",
                                                            marginBottom: "0px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        This refers to the minimum percentage of the Loan that has to be guaranteed by each of the
                                                        guarantor(s) before guaranteed mandates can be created.
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="col-md-2 col-sm-2 mt-3">
                                                <input
                                                    required={false}
                                                    id="minimumGuaranteeingPercentagePerGuarantor"
                                                    name={"minimumGuaranteeingPercentagePerGuarantor"}
                                                    // placeholder={placeholder}
                                                    className="form-control r-0 light s-12"
                                                    type="number"
                                                    value={this.state.settings.minimumGuaranteeingPercentagePerGuarantor}
                                                    disabled={false}
                                                    onChange={(e) => this.onChange(e)}
                                                    onBlur={(e) => this.onBlur(e)}
                                                    // onBlur={onBlur ? () => onBlur(value) : onBlur}
                                                />
                                                {/* {getCurrentUsersRoles().includes(
                          "generateapitoken"
                        ) && (
                         
                        )} */}
                                            </div>
                                        </div>

                                        <div
                                            className="row border"
                                            style={{
                                                padding: "0px 100px",
                                                margin: "0px 2px",
                                                backgroundColor: "#FFFFFF",
                                                // backgroundColor:
                                                //   settings.enableMandatesByDefault === true
                                                //     ? "#FFFFFF"
                                                //     : "#F6F7F9",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-md-8 col-sm-8">
                                                <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                    Configure Sweep Status Mailing List
                                                </h6>
                                                <span className="font-italic">
                                                    <p
                                                        className="deep-orange-text"
                                                        //className="deep-orange-text"
                                                        style={{
                                                            paddingBottom: "0px",
                                                            marginBottom: "0px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        Configure Users who would receive mails when sweep is in progress.
                                                    </p>
                                                </span>
                                                <div className="card-content table-responsive" style={{ overflowY: "scroll" }}>
                                                    <table className="table table-hover table-content table-sm table-bordered table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>#</strong>
                                                                </th>
                                                                {/* <th
                                  scope="col"
                                  style={{
                                    // width: "200px",
                                    paddingTop: "0.2rem",
                                    paddingBottom: "0.2rem",
                                  }}
                                >
                                  <strong>Name</strong>
                                </th> */}
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        // width: "200px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>Email Address</strong>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    style={{
                                                                        width: "5px",
                                                                        paddingTop: "0.2rem",
                                                                        paddingBottom: "0.2rem",
                                                                    }}
                                                                >
                                                                    <strong>#</strong>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sweepMailsList.length === 0 ? (
                                                                <tr>
                                                                    <td
                                                                        className="font-weight-normal font-italic text-truncate"
                                                                        colSpan={3}
                                                                        style={{
                                                                            textAlign: "center",
                                                                            color: "#FE825E",
                                                                        }}
                                                                    >
                                                                        ...
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {sweepMailsList.map((item) => (
                                                                        <tr key={item.item1}>
                                                                            <td
                                                                                style={{
                                                                                    width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {sweepMailsList.indexOf(item) + 1}
                                                                            </td>
                                                                            {/* <td
                                        style={{
                                          // width: "5px",
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.item1}
                                      </td> */}
                                                                            <td
                                                                                style={{
                                                                                    // width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                {item.item2}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    // width: "5px",
                                                                                    paddingTop: "0.2rem",
                                                                                    paddingBottom: "0.2rem",
                                                                                    fontSize: "small",
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    className="far fa-trash-alt red-text fa-lg"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={(e) => this.handleOnDeleteUserMailList(item.item1)}
                                                                                ></i>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </React.Fragment>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-sm-2 mt-3">
                                                {getCurrentUsersRoles().includes("configuresweepmaillist") && (
                                                    <Button
                                                        type="button"
                                                        variant="outline-secondary"
                                                        onClick={(e) => this.toggleMailListModal()}
                                                        className="btn-copy-code btn-sm px-2 waves-effect"
                                                    >
                                                        <i className="fa fa-copy mr-1"></i> Configure
                                                    </Button>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className="row border"
                                            style={{
                                                padding: "0px 100px",
                                                margin: "0px 2px",
                                                backgroundColor: "#FFFFFF",
                                                // backgroundColor:
                                                //   settings.enableMandatesByDefault === true
                                                //     ? "#FFFFFF"
                                                //     : "#F6F7F9",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div className="col-md-8 col-sm-8">
                                                <h6 className="font-weight-normal" style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                    View and Modify Sweep Initiation Configuration
                                                </h6>
                                                <span className="font-italic">
                                                    <p
                                                        className="deep-orange-text"
                                                        //className="deep-orange-text"
                                                        style={{
                                                            paddingBottom: "0px",
                                                            marginBottom: "0px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        View and update configuration used for sweep initiation.
                                                    </p>
                                                </span>
                                                <br />
                                            </div>
                                            <div className="col-md-2 col-sm-2 mt-3">
                                                {/* {getCurrentUsersRoles().includes(
                          "configuresweepmaillist"
                        ) && ( */}
                                                <Button
                                                    type="button"
                                                    variant="outline-secondary"
                                                    onClick={(e) => this.toggleSweepInitConfigModal()}
                                                    className="btn-copy-code btn-sm px-2 waves-effect"
                                                >
                                                    <i className="fa fa-copy mr-1"></i> Configure
                                                </Button>
                                                {/* )} */}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                    <MailListModal
                        showModal={this.state.showMailListModal}
                        toggleModal={this.toggleMailListModal}
                        handleOnAddSweepMailingList={this.handleOnAddSweepMailingList}
                        users={this.state.users}
                    />
                    <SweepInitiationConfigModal showModal={this.state.showSweepInitConfigModal} toggleModal={this.toggleSweepInitConfigModal} />
                </div>
            </div>
        );
    }
}

export default SystemSettings;
