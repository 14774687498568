import React, { Fragment } from "react";
import { decodeToken } from "react-jwt";
import BaseListComponent, { BaseListComponentState, IFilterData } from "../../../components/BaseListComponent";
import ItemsTable, { filterFieldData } from "../../../components/tableComponents/itemsTable";
import TableFilterPane from "../../../components/tableComponents/tableFilterPane";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../services/auth/authService";
import { createFilterData, mapEntityList } from "./../../../services/utility/autoMapperService";
import rolesService from "../../../services/auth/rolesService";
import appraisalInstitutionService, { filterInstitution } from "../../../services/hybridAppraisal/appraisalInstitutionService";
import appraisalPlanService from "../../../services/hybridAppraisal/appraisalPlanService";
import financialGroupService from "../../../services/hybridAppraisal/financialGroupService";
import loanRequestService from "../../../services/hybridAppraisal/loanRequestService";
import AppraisalWorkflow from "./appraisalWorkflow";
import NewApplication from "./appraisalDetailPages/newApplication";
import DataValidationView from "./appraisalDetailPages/dataValidationView";
import loanProductService from "../../../services/hybridAppraisal/loanProductService";
import OfferLetterScreen from "./appraisalDetailPages/offerLetterScreen";
import DocumentCollection from "./appraisalDetailPages/postApprovalDcoumentScreen";
import PreDisbursement from "./appraisalDetailPages/preDisbursementScreen";
import LoanDisbursement from "./appraisalDetailPages/loanDisbursementScreen";
import sweepConfigService from "../../../services/sweep/sweepConfigService";
import { toast } from "react-toastify";

export interface LoanRequestsProps { }

export interface LoanRequestsState extends BaseListComponentState {
  data: IFilterData;
  currentPage: number;
  pageSize: number;
  tableItems: any;
  totalSize: number;
  fetchingDetail: boolean;
  filterValueInput: filterFieldData;
  item: any;
  toUpdate: boolean;
  documentsToUpload: any;
  documentsToDelete: number[];
  updateAvailable: boolean;
  userRoles: any;
  systemProcess: any;
  showTable: boolean;
  showAppraisalProcess: boolean;

}

class LoanRequestsList extends BaseListComponent<LoanRequestsState> { }

class ViewLoanRequests extends React.Component<LoanRequestsProps, LoanRequestsState> {
  _isMounted = false;
  constructor(props: LoanRequestsProps) {
    super(props);
    this.state = {
      data: createFilterData(["Name", "PhoneNumber", "LoanType", "AppraisalStage", "LoanAmount", "DateCreated", "RequestStatus"]) as IFilterData,
      currentPage: 1,
      pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [
        { name: "Name", fieldType: "text", value: "" },
        { name: "PhoneNumber", fieldType: "text", value: "" },
        {
          name: "LoanType",
          fieldType: "select",
          options: ["Individual", "Corporate"],
          value: "",
        },
        { name: "AppraisalStage", fieldType: "text", value: "" },
        { name: "LoanAmount", fieldType: "number", value: "" },
        { name: "DateCreated", fieldType: "date", value: "" },
        {
            name: "RequestStatus",
            fieldType: "select",
            options: ["Created", "In Progress", "Rejected", "Completed"],
            value: "",
        }
      ],
      item: {},
      showDetailsModal: false,
      showTable: true,
      showEditModal: false,
      showTableFilterModal: false,
      showTrailModal: false,
      toUpdate: true,
      userRoles: [],
      systemProcess: [],
      showAppraisalProcess: false,
      documentsToUpload: [],
      documentsToDelete: [],
      updateAvailable: false,
    };
  }

  handlePageChange = async (currentPage: number, pageSize: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? 1 : currentPage;
    pageSize = pageSize < 0 ? this.state.pageSize : pageSize;
    if (this._isMounted) {
      this.setState({ fetching: true });
    }
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    let dataClone: IFilterData = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    // console.log("raw",data)
    dataClone["InstitutionCode"] = { item1: "=", item2: decodedToken?.["InstitutionCode"] }
    dataClone["currentLevel"] = { item1: "between", item2: `${1} and ${99}` }

    // if (dataClone.CustomerId.item2 !== "") {
    //   dataClone["JsonData"] = { item1: "like", item2: `\\"CustomerID\\":${dataClone.CustomerId.item2}` }
    // }
    // if (dataClone.CustomerTin.item2 !== "") {
    //   dataClone["TIN"] = dataClone.CustomerTin
    // }
    if (dataClone.Name.item2 !== "") {
        dataClone["CustomerName"] = dataClone.Name
    }
    if (dataClone.PhoneNumber.item2 !== "") {
        dataClone["Phonenumber"] = dataClone.PhoneNumber
    }
    if (dataClone.AppraisalStage.item2 !== "") {
        dataClone["LevelName"] = dataClone.AppraisalStage
    }
    if (dataClone.RequestStatus.item2 !== "") {
        switch (dataClone.RequestStatus.item2) {
            case "In Progress":
                dataClone.RequestStatus.item2 = "Pending";
                break;
            default:
                break;
        }
    }
    // if (dataClone.IdentificationNumber.item2 === "") {
    //   delete dataClone.IdentificationNumber
    // }
    // delete dataClone.CustomerId
    // delete dataClone.CustomerTin
    delete dataClone.Name
    delete dataClone.PhoneNumber
    delete dataClone.AppraisalStage
    // console.log("processed",dataClone)
    try {
      const loanRequests = await loanRequestService.filterLoanRequests(
        dataClone,
        pageSize,
        currentPage
      );
      loanRequests.data.item2.forEach(item => {
        try {
          const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails);
          item.productName = jsonLoanDetails?.Product?.Name
        }
        catch {
          item.productName = "";
        }


        // item.productName = jsonLoanDetails?.Product?.Name
        switch (item.currentLevel) {
          case 0:
            item.requestStatus = "New"
            break;
          case 100:
            item.requestStatus = "DocumentGeneration"
            break;
          case 101:
            item.requestStatus = "DocumentCollection"
            break;
          case 102:
            item.requestStatus = "PreDisbursement"
            break;
          case 103:
            item.requestStatus = "LoanDisbursement"
            break;
          default:
            break;
        }
        if (item.currentLevel < 100 && item.currentLevel > 0 && item.requestStatus != "Completed") {
          item.requestStatus = "In Progress"
        }
      })
      if (this._isMounted) {
        this.setState({
          tableItems: mapEntityList({
            entityList: loanRequests.data.item2,
            properties: [
              { oldName: "customerName", newName: "Name" },
              { oldName: "phonenumber", newName: "phoneNumber" },
              {
                isTag: true,
                oldName: "loanType",
                newName: "loanType",
                success: "Individual",
                danger: "Inactive",
                default: "default",
                primary: "Corporate",
                secondary: "secondary",
                warning: "warning",
                light: "light",
                dark: "dark",
              },
              { oldName: "productName", newName: "productName" },
              // { oldName: "workFlowName", newName: "workFlow" },
              { oldName: "levelName", newName: "appraisalStage" },
              { oldName: "loanAmount", newName: "loanAmount" },
              { oldName: "dateCreated", newName: "dateCreated" },
              {
                isTag: true,
                oldName: "requestStatus",
                newName: "requestStatus",
                success: "Completed",
                danger: "Rejected",
                default: "default",
                primary: "primary",
                secondary: "secondary",
                warning: "New",
                light: "light",
                dark: "dark",
              },
            ],
          }),
          currentPage,
          pageSize,
          totalSize: loanRequests.data.item1,
          fetching: false,
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
        if (this._isMounted) {
          this.setState({ fetching: false });
        }
      } else {
        if (this._isMounted) {
          this.setState({ fetching: false });
        }
      }
    } finally {
      if (this._isMounted) {
        this.setState({ fetching: false });
      }
    }
  };

  handleOnExport = async (type: string) => { };

  fetchItemById = async (item: any) => {
    if (this._isMounted) {
      this.setState({ fetchingDetail: true });
    }
    const loanRequest = await loanRequestService.retrieveById(item.id);
    const loanRequestDetail = loanRequest.data;
    // console.log("fetched :: ", loanRequestDetail)
    // console.log("Json Data :: ", JSON.parse(JSON.parse(loanRequestDetail.jsonData)))
    // console.log("jsonLoanDetails :: ", JSON.parse(JSON.parse(JSON.parse(loanRequestDetail.jsonData)).LoanDetails))
    const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(loanRequestDetail.jsonData)).LoanDetails);

    let preferredRepaymentBank: string = "";
    if (this._isMounted) {
      try {
        const response = await Promise.allSettled([
          financialGroupService.retrieveFinancialGroupByProductCode(loanRequestDetail.productCode, (item.loanAmount / 100), loanRequestDetail.institutionCode),
          loanProductService.retrieveByProductCode(loanRequestDetail.institutionCode, loanRequestDetail.productCode),
          sweepConfigService.retrieveBankNameByCBNCode(jsonLoanDetails.PreferredRepaymentBankCBNCode),
          appraisalInstitutionService.retrieveByInstitutionCode(loanRequestDetail.institutionCode)
        ])
        const financialGroup = response[0].status == "fulfilled" ? response[0].value.data : null;
        const loanProduct = response[1].status == "fulfilled" ? response[1].value.data : null;
        preferredRepaymentBank = response[2].status == "fulfilled" ? response[2].value.data : jsonLoanDetails.PreferredRepaymentBankCBNCode;
        const institutionDetail = response[3].status == "fulfilled" ? response[3].value.data : null;

        if (financialGroup === null) {
          // console.log("error caught")
          this.setState({ showDetailsModal: false, fetchingDetail: false });
          return;
        }

        loanRequestDetail["financialGroup"] = financialGroup;
        loanRequestDetail["loanProduct"] = loanProduct;
        loanRequestDetail["preferredRepaymentBank"] = preferredRepaymentBank;
        loanRequestDetail["institution"] = institutionDetail;
        // loanRequestDetail.tenureInDays = jsonLoanDetails.LoanCycle
        if (this._isMounted) {
          this.setState({ item: loanRequestDetail, fetchingDetail: false, showTable: false });
          this.setState({ item: loanRequestDetail, fetchingDetail: false, showTable: false });
        }
      }
      catch (error) {
        this.setState({ showDetailsModal: false, fetchingDetail: false });
      }
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  updateApprovalItemDocs = async (document: any) => {
    try {
      const docs = [...this.state.documentsToUpload];
      docs.push(document);
      this.setState({ documentsToUpload: docs, updateAvailable: true });
    } catch (error) {
      // log
    }
  };

  deleteApprovalItemDocs = async (document: any, documentId: number) => {
    try {
      if (document != null) {
        const docs = [...this.state.documentsToUpload];
        let selectedDoc = docs.filter(doc => doc.document === document.document && doc.name === document.name)[0]
        docs.splice(docs.indexOf(selectedDoc), 1);
        this.setState({ documentsToUpload: docs, updateAvailable: true });
      } else {
        const docs = [...this.state.documentsToDelete];
        docs.push(documentId);
        this.setState({ documentsToDelete: docs, updateAvailable: true });
      }
    } catch (error) {
      // log
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    this.handlePageChange(this.state.currentPage, this.state.pageSize);
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const response = await Promise.allSettled([
      rolesService.retrieveAllRoles(),
      appraisalInstitutionService.retrieveByInstitutionCode(decodedToken?.["InstitutionCode"])
    ])
    // console.log(decodedToken?.["UserRoleId"])
    const userRoles = response[0].status == "fulfilled" ? response[0].value.data : [];
    const userRoles2 = getCurrentUsersRoles();

    // console.log(userRoles2)
    // console.log(userRoles.filter(x => x.id === Number(decodedToken?.["UserRoleId"]))[0]?.functions.filter(x => x.roleName === "HybridAppraisal"));
    const appraisalInstitution = response[1].status == "fulfilled" ? response[1].value.data : [];
    const appraisalPlan = await appraisalPlanService.retrieveById(appraisalInstitution.planId);
    const reports = appraisalPlan.data.reports
    const validations = appraisalPlan.data.validations
    const systemProcess = [...reports, ...validations];
    if (this._isMounted) {
      this.setState({
        userRoles: userRoles,
        systemProcess: systemProcess
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleAppraisalProcess = () => {
    this.setState({
      showAppraisalProcess: !this.state.showAppraisalProcess,
      showDetailsModal: !this.state.showDetailsModal,
    });
  };

  toggleLoanDetails = () => {
    this.setState({
      showDetailsModal: !this.state.showDetailsModal,
      showAppraisalProcess: !this.state.showAppraisalProcess,
    });
  };

  render() {
    const {
      currentPage,
      pageSize,
      tableItems,
      totalSize,
      fetching,
      item,
      fetchingDetail,
      showTable,
      userRoles,
      systemProcess
    } = this.state;

    console.log(item)

    return (
      <div className="container-fluid relative animatedParent animateOnce">
        <div className="animated fadeInUpShort go">
          {showTable === true ? (
            <div className="row my-3 mx-2">
              <h3>
                <b>Loan Requests</b>
              </h3>
            </div>
          ) : ("")}
          <LoanRequestsList
            pageChangeHandler={this.handlePageChange}
            fetchingDetail={fetchingDetail}
            initialValues={{
              data: this.state.data,
              fetching: false,
              showDetailsModal: this.state.showDetailsModal,
              showEditModal: false,
              showTableFilterModal: false,
              showTrailModal: false,
              tableItems: [],
              filterValueInput: this.state.filterValueInput,
              item: {},
            }}
            tableItems={tableItems}
            item={item}
            DetailsModal={({ item }) => (
              <Fragment>
                {!this.state.showAppraisalProcess ? (
                  (() => {
                    switch (item.currentLevel) {
                      case 0:
                        return (
                          (getCurrentUsersRoles().includes("viewnewapplication") && (
                            <NewApplication
                              item={item}
                              // toggleAppraisalProcess={() => this.toggleAppraisalProcess()}
                              toggleLoanRequestDetails={this.toggleLoanDetails}
                              reloadItem={() => { }}
                              systemProcess={systemProcess}
                            />
                          ) || (toast.info(`You do not have access to view this stage`, {
                            autoClose: 6000,
                            type: toast.TYPE.DEFAULT,
                            hideProgressBar: false,
                          })
                            )
                          )
                        )
                      // case 0:
                      //   return (
                      //     (getCurrentUsersRoles().includes("viewdatavalidation") && (
                      //       <DataValidationView
                      //         item={item}
                      //         // showAppraisalProcess={this.state.showAppraisalProcess}
                      //         toggleAppraisalProcess={() => this.toggleAppraisalProcess()}
                      //         // toggleLoanRequestDetails={this.toggleLoanDetails}
                      //         reloadItem={() => { }}
                      //       />
                      //     ))
                      //   )
                      case 100:
                        return (
                          (getCurrentUsersRoles().includes("viewdocumentgeneration") && (
                            <OfferLetterScreen
                              values={item}
                            />
                          ) || (toast.info(`You do not have access to view this stage`, {
                            autoClose: 6000,
                            type: toast.TYPE.DEFAULT,
                            hideProgressBar: false,
                          })))
                        )
                      case 101:
                        return (
                          (getCurrentUsersRoles().includes("viewdocumentcollection") && (
                            <DocumentCollection
                              values={item} />
                          )
                            // : (toast.info(`You do not have access to view this stage`, {
                            //   autoClose: 6000,
                            //   type: toast.TYPE.DEFAULT,
                            //   hideProgressBar: false,
                            // }))
                          )
                        )
                      case 102:
                        return (
                          (getCurrentUsersRoles().includes("viewdisbursementinitiation") && (
                            <PreDisbursement
                              item={item}
                              values={item}
                              userRoles={userRoles}
                              systemProcess={systemProcess}
                              updateItemDocs={(document: any) => this.updateApprovalItemDocs(document)}
                              deleteItemDocs={(document: any, documentId: number) => this.deleteApprovalItemDocs(document, documentId)}
                              tempDocs={this.state.documentsToUpload}
                              tempDeletedDocs={this.state.documentsToDelete}
                            />
                          ))
                        )
                      case 103:
                        return (
                          (getCurrentUsersRoles().includes("viewdisbursementapproval") && (
                            <LoanDisbursement
                              item={item}
                              values={item}
                              userRoles={userRoles}
                              systemProcess={systemProcess}
                              updateItemDocs={(document: any) => this.updateApprovalItemDocs(document)}
                              deleteItemDocs={(document: any, documentId: number) => this.deleteApprovalItemDocs(document, documentId)}
                              tempDocs={this.state.documentsToUpload}
                              tempDeletedDocs={this.state.documentsToDelete}
                            />
                          ))
                        )
                      default:
                        return (
                          // (getCurrentUsersRoles().includes("viewappraisalprocess") && (
                          <AppraisalWorkflow
                            item={item}
                            userRoles={userRoles}
                            systemProcess={systemProcess}
                          />
                          // ))
                        )
                    }
                  })()
                ) : (
                  // (getCurrentUsersRoles().includes("viewappraisalprocess") && (
                  <AppraisalWorkflow
                    item={item}
                    userRoles={userRoles}
                    systemProcess={systemProcess}
                  />
                  // ))

                )}
              </Fragment>
            )}
            ListPayLoad={({
              data,
              showTableFilterModal,
              tableItems,
              onToggleTableFilterModal,
              onHandleFilterItemRemove,
              filterValueInput,
            }) => (
              <div className="row">
                <div className="col-md-12">
                  <div className="card no-b">
                    {showTable === true ? (
                      <div className="card-body">
                        <div className="card-title">
                          <div id="list-filters" className="ml-4 mr-4">
                            <TableFilterPane
                              filteredItems={this.getFilteredItems(data)}
                              handleFilterRemove={onHandleFilterItemRemove}
                            />
                          </div>
                          <div id="list-table">
                            <ItemsTable
                              data={data}
                              items={tableItems}
                              filterValueInput={filterValueInput}
                              showTableFiltermodal={showTableFilterModal}
                              toggleTableFiltermodal={onToggleTableFilterModal}
                              name="Loan Requests"
                              currentPage={currentPage}
                              pageSize={pageSize}
                              totalSize={totalSize}
                              fetching={fetching}
                              handlePageChange={this.handlePageChange}
                              onViewDetails={(item: any) => {
                                this.fetchItemById(item);
                              }}
                              handleOnExport={this.handleOnExport}
                              timeColumn={["dateCreated"]}
                              amountColumn={["loanAmount"]}
                            />
                          </div>
                        </div>
                      </div>
                    ) : ("")}
                  </div>
                </div>
              </div>
            )}
          ></LoanRequestsList>
        </div>
      </div >
    );
  }
}


export default ViewLoanRequests;
