import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import BaseFormComponent, { IBaseFormState } from "../../../../../../components/BaseFormComponent";
import FormInputComponent from "../../../../../../components/formInputComponent";
import SelectInputComponent, { ISelectItems } from "../../../../../../components/formSelectComponent";
import { EconomicSector } from "../../../../../../enums/economicSector";
import { LoanComputationMode } from "../../../../../../enums/loanComputationMode";
import { ProductType } from "../../../../../../enums/productType";
import textFormatService from "../../../../../../services/utility/textFormatService";
import loanRequestService from "../../../../../../services/hybridAppraisal/loanRequestService";
import { ActionTypes } from "../../../../../../enums/actionTypes";
import hybridAppraisalValidationService from "../../../../../../services/hybridAppraisal/hybridAppraisalValidationService";
import mandateService from "../../../../../../services/mandate/mandateService";
import Repayment from "./repayment";
import { getCurrentUserSession, getCurrentUsersRoles } from "../../../../../../services/auth/authService";
import supplierService from "../../../../../../services/hybridAppraisal/supplierService";
import { decodeToken } from "react-jwt";
import loanProductService from "../../../../../../services/hybridAppraisal/loanProductService";
import RepaymentSchedule from "./repaymentSchedule";
import loanCategoryService from "../../../../../../services/hybridAppraisal/loanCategoryService";
import institutionsService from "../../../../../../services/auth/institutionsService";
import { empty } from "joi";

interface LoanDetailsFields {
  loanAmount: string;
  tenureInDays: string;
  requestedLoanAmount: string;
  requestedTenureInDays: string;
  loanPurpose: string;
  interestRate: string;
  interestRateFrequency: string;
  repaymentFrequencyPrincipal: string;
  repaymentFrequencyInterest: string;
  dueDate: string;
  repaymentDay: string;
  moratorium: string;
  moratoriumPeriod: string;
  impactOnTenor: string;
  chargeInterest: string;
  spreadInterestAcrossBalanceTenor: string;
  productCode: string;
  accountName: string;
  accountNumber: string;
  accountOfficer: string;
  bankVerificationNumber: string;
  branch: string;
  defaultingLoanInterest: string;
  economicSector: string;
  securityDeposit: string;
  disbursementMode: string;
  typeOfLoan: string;
  preferredRepaymentBank: string;
  preferredRepaymentAccount: string;
  charges: any;
  accrual: string;
  loanCategory: string;
  eligibleLoanLimit: string;
  eligibleLoanTenor: string;
  loanInterestRate: string;
  loanCommission: string;
  disburseTo: string;
  supplierName: string;
  supplierBankName: string;
  supplierAccountNumber: string;
  supplierPhoneNumber: string;
  supplierAddress: string;
}

class LoanDetailsForm extends BaseFormComponent<LoanDetailsFields> { }

interface LoanDetailsProps {
  values: any;
  reloadItem: any;
  mandatoryFields: any;
  preferredRepaymentBank: string;
}
interface LoanDetailsState {
  showRepaymentSchedule: boolean;
  ownUpdate: boolean;
  item: any;
  repaymentGenerated: boolean;
  repaymentDetails: any;
  repaymentFrequency: string;
  editLoanCategory: boolean;
  hideEditButton: boolean;
  disburseTo: string;
  supplierProvided: boolean;
  suppliers: any;
  selectedSupplier: any;
  loanCommission: string;
  productPricing: any;
  loanCategory: any;
  selectedLoanCategory: any;
  institutionCategory: string;
}

class LoanDetails extends Component<
  LoanDetailsProps,
  IBaseFormState & LoanDetailsState
> {
  _isMounted: boolean;
  constructor(props: LoanDetailsProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      showRepaymentSchedule: false,
      item: {},
      ownUpdate: false,
      repaymentGenerated: false,
      repaymentDetails: {},
      repaymentFrequency: "",
      editLoanCategory: false,
      hideEditButton: false,
      disburseTo: "",
      supplierProvided: false,
      suppliers: [],
      selectedSupplier: {},
      loanCommission: "",
      productPricing: [],
      loanCategory: [],
      selectedLoanCategory: {},
      institutionCategory: ""
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const decodedToken = decodeToken(getCurrentUserSession()?.token || "")
    // const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(this.props.values.preferredRepaymentBank)).LoanDetails);
    // let bankCode = 
    // console.log(jsonLoanDetails);
    // let preferredRepaymentBank = this.props.values.preferredRepaymentBank;
    // try {
    //   preferredRepaymentBank = await (await sweepConfigService.retrieveBankNameByCBNCode(jsonLoanDetails.PreferredRepaymentBankCBNCode)).data;
    //   console.log(preferredRepaymentBank);
    // } catch (error) { }
    // console.log(this.props.values)
    let productPricing: any = []
    const institution = (await institutionsService.retrieveInstitutionByCode(decodedToken?.["InstitutionCode"])).data
    const loanCategory = await loanCategoryService.retrieveAllByInstitutionCode(decodedToken?.["InstitutionCode"]);
    const loanCategoryDetails = loanCategory.data
    const loanProduct = await loanProductService.retrieveById(this.props.values.loanProduct.id);
    const loanProductDetails = loanProduct.data
    productPricing = loanProductDetails.productPricing
    const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(this.props.values.jsonData)).LoanDetails);
    // console.log(jsonLoanDetails)
    if (!_.isEmpty(productPricing)) {
      // console.log(jsonLoanDetails.LoanFees)
      if (jsonLoanDetails?.LoanFees?.length > 0) {
        jsonLoanDetails.LoanFees.forEach(x => {
          const loanFee: any = {};
          loanFee.name = x.Name
          loanFee.processingFee = x.Amount
          loanFee.feeType = "Value"

          productPricing?.push(loanFee)
        })
        // productPricing.push(jsonLoanDetails.LoanFees)
      }
    }
    else {
      const loanFees: any = [];
      if (jsonLoanDetails?.LoanFees?.length > 0) {
        try {
          jsonLoanDetails?.LoanFees?.forEach(x => {
            const loanFee: any = {};
            loanFee.name = x.Name
            loanFee.processingFee = x.Amount
            loanFee.feeType = "Value"
            loanFees.push(loanFee)
          })
        } catch {
          const loanFee: any = {};
          loanFees.push(loanFee)
        }
      }

      productPricing = loanFees
    }
    // console.log(productPricing)
    this.setState({ productPricing: productPricing, loanCategory: loanCategoryDetails, institutionCategory: institution.category })
    // console.log(loanProductDetails)

    var loanCommissionDetails = loanProductDetails.productPricing?.filter(x => x.name.includes("Commission"))[0]

    if (loanCommissionDetails) {
      var loanCommission = loanCommissionDetails.feeType === "Percentage" ? textFormatService.formatCurrency(((loanCommissionDetails.value / 100) * (this.props.values.loanAmount / 100)), 2) : loanCommissionDetails.value;
      this.setState({ loanCommission: loanCommission })
    }
    // const decodedToken = decodeToken(getCurrentUserSession().token || "");
    const suppliers = await supplierService.retrieveAll();
    const supplierDetails = suppliers.data.filter(x => x.institutionCode === decodedToken?.["InstitutionCode"])
    const supplier = this.props.values.supplierName
    if (supplier !== null || supplier != "") {
      this.setState({ supplierProvided: false, suppliers: supplierDetails })
    }
    else {
      this.setState({ supplierProvided: true })
    }

  }
  static getDerivedStateFromProps(
    nextProps: LoanDetailsProps,
    prevState: LoanDetailsState
  ) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isNull(nextProps.values)) {
      return null;
    }
    return {
      item: nextProps.values
    };
  }

  toggleShowRepaymentSchedule = async () => {
    await this.fetchLoanDetails()
    this.setState({ showRepaymentSchedule: !this.state.showRepaymentSchedule, ownUpdate: true });
  };

  onFormSubmit(fields: LoanDetailsFields, onReloadFieldsCB: any): boolean {
    const { mandatoryFields, values } = this.props;
    const { editLoanCategory } = this.state;
    try {
      this.setState(
        { errors: hybridAppraisalValidationService.validateLoanDetailsForm(fields, values.loanType, editLoanCategory, mandatoryFields), ownUpdate: true },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            return this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return false;
    } catch (error) {
      return false;
    }
  }

  onSelectSupplier = (value) => {
    var supplier = this.state.suppliers.filter(x => x.name === value);
    // console.log(supplier)
    this.setState({ selectedSupplier: supplier[0], ownUpdate: true });
  }

  onSelectLoanCategory = (value) => {
    var loanCategory = this.state.loanCategory.filter(x => x.name === value);
    // console.log(supplier)
    this.setState({ selectedLoanCategory: loanCategory[0], ownUpdate: true });
  }

  async submit(
    fields: LoanDetailsFields,
    onReloadFieldsCB: any
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true, ownUpdate: true });
      const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
      const loanRequestDetail = loanRequest.data;
      const payload: any = { ...loanRequestDetail };
      const charges: any = [];

      const loanDetails: any = {};
      payload.loanAmount = Number(fields.loanAmount) * 100;
      payload.tenureInDays = Number(fields.tenureInDays);
      payload.loanPurpose = fields.loanPurpose;
      loanDetails.interestRate = Number(fields.interestRate);
      loanDetails.interestRateFrequency = fields.interestRateFrequency;
      loanDetails.repaymentFrequencyPrincipal = fields.repaymentFrequencyPrincipal;
      loanDetails.repaymentFrequencyInterest = fields.repaymentFrequencyInterest;
      loanDetails.dueDate = Number(fields.dueDate);
      loanDetails.accrual = fields.accrual ? fields.accrual : "Fixed"
      loanDetails.repaymentDay = fields.repaymentDay;
      loanDetails.moratorium = fields.moratorium;
      loanDetails.moratoriumPeriod = Number(fields.moratoriumPeriod);
      loanDetails.impactOnTenor = fields.impactOnTenor;
      loanDetails.chargeInterest = fields.chargeInterest;
      loanDetails.spreadInterestAcrossBalanceTenor = fields.spreadInterestAcrossBalanceTenor;
      loanDetails.accountName = fields.accountName;
      loanDetails.accountNumber = fields.accountNumber;
      loanDetails.accountOfficer = fields.accountOfficer;
      loanDetails.bankVerificationNumber = fields.bankVerificationNumber;
      loanDetails.branch = fields.branch;
      loanDetails.defaultingLoanInterest = Number(fields.defaultingLoanInterest);
      loanDetails.economicSector = fields.economicSector;
      loanDetails.securityDeposit = fields.securityDeposit;
      loanDetails.disbursementMode = fields.disbursementMode;
      loanDetails.typeOfLoan = fields.typeOfLoan;
      loanDetails.preferredRepaymentBank = fields.preferredRepaymentBank;
      loanDetails.preferredRepaymentAccount = fields.preferredRepaymentAccount;
      loanDetails.charges = fields.charges === 0 ? charges : charges;
      loanDetails.loanCategory = fields.loanCategory
      loanDetails.eligibleLoanLimit = fields.eligibleLoanLimit
      loanDetails.eligibleLoanTenor = fields.eligibleLoanTenor
      loanDetails.loanInterestRate = fields.loanInterestRate
      loanDetails.disburseTo = this.state.disburseTo
      loanDetails.supplierName = fields.supplierName
      loanDetails.supplierBankName = fields.supplierBankName ? fields.supplierBankName : this.state.selectedSupplier.bankName
      loanDetails.supplierAccountNumber = fields.supplierAccountNumber ? fields.supplierAccountNumber : this.state.selectedSupplier.accountNumber
      loanDetails.supplierPhoneNumber = fields.supplierPhoneNumber ? fields.supplierPhoneNumber : this.state.selectedSupplier.phoneNumber
      loanDetails.supplierAddress = fields.supplierAddress ? fields.supplierAddress : this.state.selectedSupplier.address
      payload.loanDetails = loanDetails;

      var newRepayment = await this.generateRepaymentSchedule(payload);
      // console.log(newRepayment)

      var firstParse = JSON.parse(JSON.parse(loanRequestDetail.jsonData))
      // console.log(firstParse)
      firstParse.CollectionPaymentSchedules = newRepayment
      firstParse.LoanAmount = Number(fields.loanAmount) * 100;
      firstParse.LoanTenure = Number(fields.tenureInDays);
      var LoanDetails = JSON.parse(firstParse.LoanDetails)
      // console.log(LoanDetails)
      LoanDetails.InterestRate = Number(fields.interestRate);
      LoanDetails.LoanCycle = Number(fields.tenureInDays);
      LoanDetails.LoanAmount = Number(fields.loanAmount) * 100;
      LoanDetails.InterestPaymentFrequency = this.getRepaymentFrequencyCode(fields.repaymentFrequencyInterest === "Semi-Annually" ? "HalfYearly" : fields.repaymentFrequencyInterest)
      LoanDetails.PrincipalPaymentFrequency = this.getRepaymentFrequencyCode(fields.repaymentFrequencyPrincipal === "Semi-Annually" ? "HalfYearly" : fields.repaymentFrequencyPrincipal)
      LoanDetails.LoanAmountToNaira = textFormatService.formatCurrency(Number(fields.loanAmount), 2)
      LoanDetails.Moratarium = fields.moratorium === "Yes" ? 1 : 0
      LoanDetails.InterestAccrualMode = this.getInterestAccrualCode(fields.accrual ? fields.accrual : "Fixed")
      firstParse.LoanDetails = JSON.stringify(LoanDetails)
      var JSONData = JSON.stringify(JSON.stringify(firstParse));
      payload.jsonData = JSONData;

      // console.log(payload)

      // const repaymentSchedule = await this.generateRepaymentSchedule(fields, loanRequestDetail);

      const response = await loanRequestService.updateLoanRequest(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Loan Details saved successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      } else {
        return false;
      }
      this.setState({ submitting: false, ownUpdate: true });
      this.resetFields();
      onReloadFieldsCB();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false, ownUpdate: true });
      return false;
    } finally {
      return true;
    }
  }

  fetchLoanDetails = async () => {
    const loanRequest = await loanRequestService.retrieveById(this.props.values.id);
    const loanRequestDetail = loanRequest.data;

    this.setState({ repaymentDetails: loanRequestDetail })
  }

  getRepaymentFrequencyCode = (frequency: string) => {
    var code =
      frequency === "Daily" ? 0 :
        frequency === "Weekly" ? 1 :
          frequency === "Monthly" ? 2 :
            frequency === "Quarterly" ? 3 :
              frequency === "Annually" ? 4 :
                frequency === "BiMonthly" ? 5 :
                  frequency === "HalfYearly" ? 6 :
                    frequency === "Termly" ? 8 : 0

    return code;

  }
  getInterestAccrualCode = (accrual: string) => {
    var code =
      accrual === "Fixed" ? 0 :
        accrual === "Reducing" ? 1 :
          accrual === "ReducingButEqual" ? 2 : 0

    return code;
  }

  handleRepaymentFrequencyChange = async (status: string) => {
    // if (status === "Weekly") {
    await this.setState({ repaymentFrequency: status, ownUpdate: true });
    // }
    // else {
    //   if (this.state.repaymentFrequency) {
    //     await this.setState({ repaymentFrequency: !this.state.repaymentFrequency, ownUpdate: true });
    //   }
    //   else {
    //     await this.setState({ repaymentFrequency: this.state.repaymentFrequency, ownUpdate: true });
    //   }

    // }
  }

  enableLoanCategory = async () => {
    // if (status === "Weekly") {
    await this.setState({ editLoanCategory: !this.state.editLoanCategory, hideEditButton: !this.state.hideEditButton, ownUpdate: true });

  }

  onChangeDisburseTo = async (id, value) => {
    await this.setState({ disburseTo: value, ownUpdate: true });
  }

  isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }
  convertToAnnualRate(frequency, rate) {
    const currentYear = new Date().getFullYear();
    const daysInYear = this.isLeapYear(currentYear) ? 366 : 355;
    let annualRate;

    switch (frequency.toLowerCase()) {
      case 'daily':
        annualRate = rate * daysInYear;
        break;
      case 'weekly':
        annualRate = rate * 52;
        break;
      case 'monthly':
        annualRate = rate * 12;
        break;
      case 'annually':
        annualRate = rate;
        break;
      default:
        throw new Error('Invalid frequency');
    }

    return annualRate;
  }

  generateRepaymentSchedule = async (loanRequest) => {
    try {
      const payload: any = {};
      const account: any = {};
      const config: any = {};
      const publicHolidays: any = [];
      // const loanRequestDetail = await loanRequestService.retrieveById(this.props.item.id);
      // const loanRequest = loanRequestDetail.data;
      // const loanRequest = { ...this.props.item }
      // console.log(loanRequest)
      const fields = loanRequest.loanDetails
      // console.log(jsonLoanDetails)

      var d = new Date();
      var day = d.getDay();
      var tenure = 0
      var subString = 0

      if (fields.repaymentFrequencyPrincipal === "Daily") {
        tenure = Math.round(Number(loanRequest.tenureInDays));
        subString = 10
      }
      else if (fields.repaymentFrequencyPrincipal === "Weekly") {
        tenure = Math.round(Number(loanRequest.tenureInDays / 7));
        subString = 10
      }
      else if (fields.repaymentFrequencyPrincipal === "Monthly") {
        tenure = Math.round(Number(loanRequest.tenureInDays / 30));
        if (fields.repaymentFrequencyInterest === "Weekly") {
          subString = 10
        }
        else {
          subString = 7
        }
      }
      else if (fields.repaymentFrequencyPrincipal === "Quarterly") {
        tenure = Math.round(Number(loanRequest.tenureInDays / 91));
        if (fields.repaymentFrequencyInterest === "Weekly") {
          subString = 10
        }
        else {
          subString = 7
        }
      }
      else if (fields.repaymentFrequencyPrincipal === "Semi-Annually") {
        tenure = Math.round(Number(loanRequest.tenureInDays / 183));
        if (fields.repaymentFrequencyInterest === "Weekly") {
          subString = 10
        }
        else {
          subString = 7
        }

      }
      else if (fields.repaymentFrequencyPrincipal === "Annually") {
        tenure = Math.round(Number(loanRequest.tenureInDays / 365));
        if (fields.repaymentFrequencyInterest === "Weekly") {
          subString = 10
        }
        else {
          subString = 7
        }
      }

      if (!_.isEmpty(fields.repaymentDay)) {
        var targetDay = fields.repaymentDay === "Sunday" ? 0 : fields.repaymentDay === "Monday" ? 1 : fields.repaymentDay === "Tuesday" ? 2 :
          fields.repaymentDay === "Wednesday" ? 3 : fields.repaymentDay === "Thursday" ? 4 : fields.repaymentDay === "Friday" ? 5 :
            fields.repaymentDay === "Saturday" ? 6 : 0
      }

      var interestRate = this.convertToAnnualRate(fields.interestRateFrequency, fields.interestRate)

      // console.log(tenure)
      account.interestAccrualMode = fields.accrual ? fields.accrual : "Fixed";
      account.id = 0
      account.dateCreatedFinancial = loanRequest.dateCreated
      account.moratarium = fields.moratorium === "Yes" || fields.moratorium !== null ? Number(fields.moratoriumPeriod) : 0
      account.loanCycle = loanRequest.tenureInDays //tenure === 0 ? 1 : tenure;
      account.computationMode = "Simple"
      account.computationModeMultiple = 0;
      account.loanAmount = Number(loanRequest.loanAmount / 100)
      account.principalPaymentFrequency = fields.repaymentFrequencyPrincipal === "Semi-Annually" ? "HalfYearly" : fields.repaymentFrequencyPrincipal
      account.principalRepaymentType = "Proratedrepayment"
      account.interestAccrualCommenceDate = loanRequest.dateCreated
      account.interestRate = interestRate;
      account.discountAmount = 0
      account.currentFinancialDate = loanRequest.dateCreated
      account.ledgerBalance = 0
      account.number = ""
      account.interestRepaymentType = "Proratedrepayment"
      account.interestPaymentFrequency = fields.repaymentFrequencyInterest === "Semi-Annually" ? "HalfYearly" : fields.repaymentFrequencyInterest

      config.applyMoratoriumOnPrincipal = fields.moratorium === "Yes" && fields.moratorium !== null ? true : false
      config.applyMoratoriumOnInterest = fields.moratorium === "Yes" && fields.moratorium !== null ? true : false
      config.daysInAYear = 360
      config.isMonday = day === 1 ? true : false
      config.isWednesday = day === 3 ? true : false
      config.isFriday = day === 5 ? true : false
      config.isSunday = day === 0 ? true : false
      config.isTuesday = day === 2 ? true : false
      config.isThursday = day === 4 ? true : false
      config.isSaturday = day === 6 ? true : false
      config.allowSameLoanRepaymentDate = false
      config.includeMoratoriumAsPartOfTenure = fields.moratorium === "Yes" && fields.moratorium !== null ? true : false
      config.skipWeekendsAndPublicHolidaysForLoans = false

      payload.account = account
      payload.config = config
      payload.publicHolidays = publicHolidays

      const response = await mandateService.calculateLoanPaymentSchedule("100317", payload);
      if (response.status >= 200 && response.status <= 300) {

        // var collectionSchedule = response.data.reduce((acc, obj) => {
        //     // console.log(obj.paymentDueDate)
        //     // console.log(obj.paymentDueDate.substring(0, 7))
        //     var existingDate = acc.find(item => item.paymentDueDate.substring(0, 7) === obj.paymentDueDate.substring(0, 7));
        //     if (existingDate) {
        //         existingDate.amount = existingDate.amount + obj.amount;
        //         return acc;
        //     }
        //     acc.push(obj);
        //     return acc;
        // }, []);
        // console.log(response.data)
        var prinInterestRepayment = account.principalPaymentFrequency !== account.interestPaymentFrequency ? response.data.reduce((acc, obj) => {
          var existingDate = acc.find(item =>
            // console.log(item.paymentDueDate));
            item.paymentDueDate.substring(6, 15) === obj.paymentDueDate.substring(6, 15));
          // console.log(existingDate)
          if (existingDate) {
            existingDate.amount = existingDate.amount + obj.amount;
            existingDate.paymentType = "PrinTerestPayment"
            return acc;
          }
          acc.push(obj);
          return acc;
        }, []) : response.data.filter(x => x.paymentType === "InterestPayment")
        var InterestAmount = response.data.filter(x => x.paymentType === "InterestPayment")[0]?.amount;
        var principalAmount = response.data.filter(x => x.paymentType === "PrincipalPayment")[0]?.amount;
        var TenuredAmount = Number(Number(principalAmount) / tenure).toFixed(2)

        var cumulativePrincipal = 0
        var cumulativeTotal = 0
        var outstandingPricipal = Number(loanRequest.loanAmount / 100).toFixed(2)
        var testDate = new Date(prinInterestRepayment[0].paymentDueDate);
        // console.log(prinInterestRepayment)
        // console.log(account.interestPaymentFrequency)

        // console.log(new Date(testDate.getFullYear(), testDate.getMonth(), fields.dueDate, testDate.getHours(), testDate.getMinutes(), testDate.getSeconds(), testDate.getMilliseconds()))


        prinInterestRepayment.forEach(x => {
          if (x.isApplied === true) {
            return;
          }
          else {
            var total = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(Number(x.amount) + Number(TenuredAmount)).toFixed(2) : x.paymentType === "InterestPayment" ? Number(x.amount).toFixed(2) : Number(Number(TenuredAmount) + Number(x.amount)).toFixed(2)
            var newCumulativePrincipal = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(Number(TenuredAmount) + Number(cumulativePrincipal)).toFixed(2) : x.paymentType === "InterestPayment" ? Number(cumulativePrincipal).toFixed(2) : Number(Number(TenuredAmount) + Number(cumulativePrincipal)).toFixed(2)
            var newCumulativeTotal = Number(Number(cumulativeTotal) + Number(total)).toFixed(2)
            var newOutstandingPrincipal = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(Number(outstandingPricipal) - Number(TenuredAmount)).toFixed(2) : x.paymentType === "InterestPayment" ? Number(outstandingPricipal).toFixed(2) : Number(Number(outstandingPricipal) - Number(TenuredAmount)).toFixed(2)

            // if (x.paymentType === "PrincipalPayment" || account.principalPaymentFrequency === account.interestPaymentFrequency) {
            if (x.paymentType === "PrincipalPayment" || x.paymentType === "PrinTerestPayment") {
              if (account.principalPaymentFrequency !== "Weekly" && account.principalPaymentFrequency !== "Daily") {
                // var dueDate = new Date(x.paymentDueDate)
                // x.paymentDueDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), fields.dueDate, dueDate.getHours(), dueDate.getMinutes(), dueDate.getSeconds(), dueDate.getMilliseconds());
              }
              else if (account.principalPaymentFrequency === "Weekly" && !_.isEmpty(fields.repaymentDay)) {
                var dueDate = new Date(x.paymentDueDate)
                const currentDay = dueDate.getDay();
                const diff = (targetDay - currentDay + 7) % 7; // Calculate the difference to the target day
                x.RepaymentDate = dueDate.setDate(dueDate.getDate() + diff);
                x.paymentDueDate = dueDate.setDate(dueDate.getDate() + diff);

              }
            }
            if (x.paymentType === "InterestPayment") {
              if (account.interestPaymentFrequency !== "Weekly" && account.interestPaymentFrequency !== "Daily") {
                // var dueDate = new Date(x.paymentDueDate)
                // x.paymentDueDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), fields.dueDate, dueDate.getHours(), dueDate.getMinutes(), dueDate.getSeconds(), dueDate.getMilliseconds());
              }
              else if (account.principalPaymentFrequency === "Weekly" && !_.isEmpty(fields.repaymentDay)) {
                var dueDate = new Date(x.paymentDueDate)
                const currentDay = dueDate.getDay();
                const diff = (targetDay - currentDay + 7) % 7; // Calculate the difference to the target day
                x.RepaymentDate = dueDate.setDate(dueDate.getDate() + diff);
                x.paymentDueDate = dueDate.setDate(dueDate.getDate() + diff);

              }

            }
            // }
            // x.amount = Number(x.amount) + Number(TenuredAmount)
            x.RepaymentDate = x.paymentDueDate
            x.principal = account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(TenuredAmount).toFixed(2) : x.paymentType === "InterestPayment" ? 0 : Number(TenuredAmount).toFixed(2)
            x.interest = account.interestAccrualMode !== "Fixed" ? Number(x.amount).toFixed(2) : account.principalPaymentFrequency === account.interestPaymentFrequency ? Number(InterestAmount).toFixed(2) : x.paymentType === "InterestPayment" ? Number(x.amount).toFixed(2) : x.paymentType === "PrinTerestPayment" ? Number(x.amount).toFixed(2) : 0
            x.cumulativePrincipal = newCumulativePrincipal
            x.cumulativeTotal = newCumulativeTotal
            x.RepaymentAmountInNaira = total
            x.total = total
            x.outstandingPricipal = newOutstandingPrincipal
            cumulativePrincipal = Number(newCumulativePrincipal)
            cumulativeTotal = Number(newCumulativeTotal)
            outstandingPricipal = newOutstandingPrincipal
          }

        });
        prinInterestRepayment = prinInterestRepayment.filter(x => x.isApplied === false)

        return prinInterestRepayment;
      } else {
        var prinInterestRepayment: any = []
        return prinInterestRepayment;
      }

      // var newRepayment = prinInterestRepayment.map(x => {
      //   return { RepaymentDate: x.paymentDueDate, RepaymentAmountInNaira: x.total }

      // })


      // const resp = await loanRequestService.updateLoanRequest(loanRequest);
      // this.setState({ submitting: false, ownUpdate: true, repaymentDetails: prinInterestRepayment });
      // this.resetFields();


    } catch (error) {
      var prinInterestRepayment: any = []
      toast.error(error.response.data.detail, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      // this.setState({ submitting: false, ownUpdate: true });
      // var prinInterestRepayment: any = []
      return prinInterestRepayment;
    }
  }

  getRequiredFields(title): boolean {
    const { mandatoryFields } = this.props;
    var required = mandatoryFields?.inputFields?.filter(x => x.fieldName === title)[0]?.mandatory;
    if (required === undefined) {
      return false;
    }
    return required;
  }

  resetFields() {
    this.setState({ validated: false, submitting: false, errors: [], ownUpdate: true });
  }

  render() {
    const { values } = this.props;
    const { errors, validated, repaymentFrequency, supplierProvided, suppliers, selectedSupplier, productPricing, repaymentDetails, loanCategory, selectedLoanCategory,
      institutionCategory
    } = this.state;
    const jsonLoanDetails = JSON.parse(JSON.parse(JSON.parse(values.jsonData)).LoanDetails);
    console.log(institutionCategory)

    const repaymentFrequencyPrincipalInputData: ISelectItems[] = ["Daily", "Weekly", "Monthly", "Quarterly", "Semi-Annually", "Annually"]
      .map(item => {
        return { name: item, value: item }
      });

    const interestRateFrequencyInputData: ISelectItems[] = ["Daily", "Weekly", "Monthly", "Annually"]
      .map(item => {
        return { name: item, value: item }
      });

    const repaymentDayInputData: ISelectItems[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
      .map(item => {
        return { name: item, value: item }
      });
    const repaymentFrequencyInterestInputData = repaymentFrequencyPrincipalInputData


    const supplierBankNameInputType: ISelectItems[] = ["Access", "Citibank", "Ecobank", "Fidelity", "FirstBank", "GTB", "Heritage",
      "Keystone", "Polaris", "Providus", "StanbicIBTC", "StandardChattered", "Sterling", "SunTrust", "Union", "Unity", "Wema", "Zenith"].map(item => {
        return { name: item, value: item }
      });

    const dueDateInputData: ISelectItems[] = _.range(1, 32, 1).map(item => {
      return { name: item.toString(), value: item }
    });

    const moratoriumInputData: ISelectItems[] = ["Yes", "No"].map(item => {
      return { name: item, value: item }
    });
    const chargeInterestInputData: ISelectItems[] = [
      "Principal Only",
      "Interest Only",
      "Principal & Interest"
    ].map(item => {
      return { name: item, value: item }
    });

    const disburseToInputData: ISelectItems[] = [
      "Customer",
      "Supplier"
    ].map(item => {
      return { name: item, value: item }
    });

    const accrualInputData: ISelectItems[] = [
      "Fixed",
      "Reducing",
      "ReducingButEqual"
    ].map(item => {
      return { name: item, value: item }
    });

    let loanPurposeInputData: ISelectItems[] = [
      { name: "Personal", value: "Personal" },
      { name: "Vehicle Acquisition", value: "VehicleAcquisition" },
      { name: "Home Acquisition", value: "HomeAcquisition" },
      { name: "Business Finance", value: "BusinessFinance" }
    ];

    const supplierNameInputData: ISelectItems[] =
      suppliers.map(item => {
        return { name: item.name, value: item.name }
      });

    const impactOnTenorInputData: ISelectItems[] = ["Extend Tenor by Number of Moratorium months", "Retain Tenor"].map(item => {
      return { name: item, value: item }
    });

    const spreadInterestInputData: ISelectItems[] = [
      "Charge Accumulated Interest on First Repayment Month after Moratorium",
      "Spread Interest across Balance Tenor after Moratorium"
    ].map(item => {
      return { name: item, value: item }
    });

    const loanCategoryInputData: ISelectItems[] = loanCategory.map(item => {
      return { name: item.name, value: item.name }
    });

    // console.log(selectedSupplier)


    return (
      <LoanDetailsForm
        initialValues={{
          loanAmount: (values.loanAmount / 100).toString(),
          tenureInDays: jsonLoanDetails.LoanCycle.toString(),
          requestedLoanAmount: values?.requestedLoanAmount ? (values?.requestedLoanAmount / 100)?.toString() : "",
          requestedTenureInDays: values?.requestedTenureInDays ? values?.requestedTenureInDays?.toString() : "",
          loanPurpose: values.loanPurpose,
          interestRate: (values.loanDetails?.interestRate) ? values.loanDetails.interestRate.toString() : jsonLoanDetails.InterestRate.toString(),
          interestRateFrequency: values.loanDetails?.interestRateFrequency ? values.loanDetails?.interestRateFrequency : "",
          repaymentFrequencyPrincipal: values.loanDetails?.repaymentFrequencyPrincipal ? values.loanDetails.repaymentFrequencyPrincipal : jsonLoanDetails.PrincipalPaymentFrequency,
          repaymentFrequencyInterest: values.loanDetails?.repaymentFrequencyInterest ? values.loanDetails.repaymentFrequencyInterest : jsonLoanDetails.InterestPaymentFrequency,
          dueDate: values.loanDetails?.dueDate ? values.loanDetails.dueDate.toString() : "",
          repaymentDay: values.loanDetails?.repaymentDay ? values.loanDetails?.repaymentDay : "",
          moratorium: values.loanDetails?.moratorium ? values.loanDetails.moratorium : jsonLoanDetails.Moratarium,
          moratoriumPeriod: values.loanDetails?.moratoriumPeriod ? values.loanDetails.moratoriumPeriod.toString() : "",
          impactOnTenor: values.loanDetails?.impactOnTenor ? values.loanDetails.impactOnTenor : "",
          chargeInterest: values.loanDetails?.chargeInterest ? values.loanDetails.chargeInterest : "",
          spreadInterestAcrossBalanceTenor: values.loanDetails?.spreadInterestAcrossBalanceTenor ? values.loanDetails.spreadInterestAcrossBalanceTenor : "",
          productCode: values.loanDetails?.productCode ? values.loanDetails.productCode : jsonLoanDetails.Product.Code,
          accountName: values.loanDetails?.accountName ? values.loanDetails.accountName : jsonLoanDetails.Name,
          accountNumber: values.loanDetails?.accountNumber ? values.loanDetails.accountNumber : values.linkedAccountNo,
          accountOfficer: values.loanDetails?.accountOfficer ? values.loanDetails.accountOfficer : jsonLoanDetails.AccountOfficer,
          bankVerificationNumber: values.loanDetails?.bankVerificationNumber ? values.loanDetails.bankVerificationNumber : values.bvn,
          branch: values.loanDetails?.branch ? values.loanDetails.branch : jsonLoanDetails.Branch,
          defaultingLoanInterest: values.loanDetails?.defaultingLoanInterest ? values.loanDetails.defaultingLoanInterest : jsonLoanDetails.DefaultingLoanInterest,
          economicSector: jsonLoanDetails.EconomicSector ? textFormatService.splitAtUpper(EconomicSector[jsonLoanDetails.EconomicSector]) : "",
          securityDeposit: values.loanDetails?.securityDeposit ? values.loanDetails.securityDeposit : jsonLoanDetails.SecurityDeposit,
          disbursementMode: values.loanDetails?.disbursementMode ? values.loanDetails.disbursementMode : jsonLoanDetails.DisbursementMode,
          typeOfLoan: jsonLoanDetails.ComputationMode ? textFormatService.splitAtUpper(LoanComputationMode[jsonLoanDetails.ComputationMode]) : "",
          preferredRepaymentBank: values.preferredRepaymentBank,
          preferredRepaymentAccount: values.loanDetails?.preferredRepaymentAccount ? values.loanDetails?.preferredRepaymentAccount : jsonLoanDetails.PreferredRepaymentAccount,
          charges: jsonLoanDetails.LoanFees,
          accrual: values.loanDetails?.accrual,
          loanCategory: values.loanDetails?.loanCategory,
          eligibleLoanLimit: values.loanDetails?.eligibleLoanLimit,
          eligibleLoanTenor: values.loanDetails?.eligibleLoanTenor,
          loanInterestRate: values.loanDetails?.loanInterestRate,
          loanCommission: values.loanCommission,
          supplierAccountNumber: values.loanDetails?.supplierAccountNumber,
          supplierBankName: values.loanDetails?.supplierBankName,
          supplierAddress: values.loanDetails?.supplierAddress,
          supplierName: values.loanDetails?.supplierName,
          supplierPhoneNumber: values.loanDetails?.supplierPhoneNumber,
          disburseTo: values.loanDetails?.disburseTo

        }}
        FormComponent={({
          fields: {
            loanAmount,
            tenureInDays,
            requestedLoanAmount,
            requestedTenureInDays,
            loanPurpose,
            interestRate,
            interestRateFrequency,
            repaymentFrequencyPrincipal,
            repaymentFrequencyInterest,
            dueDate,
            repaymentDay,
            accrual,
            moratorium,
            moratoriumPeriod,
            impactOnTenor,
            chargeInterest,
            spreadInterestAcrossBalanceTenor,
            productCode,
            accountName,
            accountNumber,
            accountOfficer,
            bankVerificationNumber,
            branch,
            defaultingLoanInterest,
            economicSector,
            securityDeposit,
            disbursementMode,
            typeOfLoan,
            preferredRepaymentBank,
            preferredRepaymentAccount,
            charges,
            loanCategory,
            loanInterestRate,
            eligibleLoanLimit,
            eligibleLoanTenor,
            loanCommission,
            supplierAccountNumber,
            supplierBankName,
            supplierAddress,
            supplierName,
            supplierPhoneNumber,
            disburseTo
          },
          onChange,
          onReloadFields,
          onHandleSubmit,
        }) => (
          <div className="form-row">
            <div className="col-md-12">

              {/* Loan Information */}
              <div className="card">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Loan Information</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <FormInputComponent
                      label="Requested Loan Amount"
                      id="requestedLoanAmount"
                      name="requestedLoanAmount"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={requestedLoanAmount}
                      required={this.getRequiredFields("loanAmount")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled
                    />
                    <FormInputComponent
                      label="Requested Loan Tenor (In Days)"
                      id="requestedTenureInDays"
                      name="requestedTenureInDays"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={requestedTenureInDays}
                      required={this.getRequiredFields("loanTenor")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled
                    />

                    <FormInputComponent
                      label="New Loan Amount"
                      id="loanAmount"
                      name="loanAmount"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={loanAmount}
                      required={this.getRequiredFields("loanAmount")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <FormInputComponent
                      label="New Loan Tenor (In Days)"
                      id="tenureInDays"
                      name="tenureInDays"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={tenureInDays}
                      required={this.getRequiredFields("loanTenor")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      id="loanPurpose"
                      name="loanPurpose"
                      divClass={6}
                      value={loanPurpose}
                      items={loanPurposeInputData}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <FormInputComponent
                      id="loanCommission"
                      name="loanCommission"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={this.state.loanCommission}
                      required={this.getRequiredFields("loanCommission")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled
                    />
                    <FormInputComponent
                      label="Product Interest Rate"
                      id="interestRate"
                      name="interestRate"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={interestRate}
                      required={this.getRequiredFields("interestRate")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    // disabled
                    />
                    <SelectInputComponent
                      label="Interest Rate Frequency"
                      id="interestRateFrequency"
                      name="interestRateFrequency"
                      divClass={6}
                      value={interestRateFrequency}
                      items={interestRateFrequencyInputData}
                      required={this.getRequiredFields("interestRateFrequency")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      label="Repayment Frequency (Principal)"
                      id="repaymentFrequencyPrincipal"
                      name="repaymentFrequencyPrincipal"
                      divClass={6}
                      value={repaymentFrequencyPrincipal}
                      items={repaymentFrequencyPrincipalInputData}
                      required={this.getRequiredFields("repaymentFrequencyPrincipal")}
                      validated={validated}
                      errors={errors}
                      onChange={async (id, value) => {
                        await onChange(id, value);
                        await this.handleRepaymentFrequencyChange(value)
                      }}
                    />
                    <SelectInputComponent
                      label="Repayment Frequency (Interest)"
                      id="repaymentFrequencyInterest"
                      name="repaymentFrequencyInterest"
                      divClass={6}
                      value={repaymentFrequencyInterest}
                      items={repaymentFrequencyInterestInputData}
                      required={this.getRequiredFields("repaymentFrequencyInterest")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      label="Interest Accrual"
                      id="accrual"
                      name="accrual"
                      divClass={6}
                      value={accrual}
                      items={accrualInputData}
                      required={this.getRequiredFields("interestAccrual")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      label="Due Day"
                      id="dueDate"
                      name="dueDate"
                      divClass={6}
                      value={dueDate}
                      items={dueDateInputData}
                      required={this.getRequiredFields("dueDate")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />

                    {repaymentFrequency === "Weekly" ? (
                      <SelectInputComponent
                        label="Repayment Day"
                        id="repaymentDay"
                        name="repaymentDay"
                        divClass={6}
                        value={repaymentDay}
                        items={repaymentDayInputData}
                        required={false}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                      />
                    ) : ("")}

                  </div>
                </div>
              </div>

              {/* Moratorium Information */}
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Moratorium Details</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <SelectInputComponent
                      label="Moratorium"
                      id="moratorium"
                      name="moratorium"
                      divClass={6}
                      value={moratorium}
                      items={moratoriumInputData}
                      required={this.getRequiredFields("moratorium")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <FormInputComponent
                      label="Moratorium Period (In Months)"
                      id="moratoriumPeriod"
                      name="moratoriumPeriod"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={moratoriumPeriod}
                      required={this.getRequiredFields("moratoriumPeriod")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      label="Impact On Tenor"
                      id="impactOnTenor"
                      name="impactOnTenor"
                      divClass={6}
                      value={impactOnTenor}
                      items={impactOnTenorInputData}
                      required={this.getRequiredFields("impactOnTenor")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    <SelectInputComponent
                      label="Charge Moratorium On"
                      id="chargeInterest"
                      name="chargeInterest"
                      divClass={6}
                      value={chargeInterest}
                      items={chargeInterestInputData}
                      required={this.getRequiredFields("chargeInterest")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                    />
                    {/* <SelectInputComponent
                      label="Interest Treatment"
                      id="spreadInterestAcrossBalanceTenor"
                      name="spreadInterestAcrossBalanceTenor"
                      divClass={6}
                      value={spreadInterestAcrossBalanceTenor}
                      items={spreadInterestInputData}
                      required={this.getRequiredFields("firstName")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={chargeInterest != "No"}
                    /> */}
                  </div>
                </div>
              </div>

              {/* Product Information */}
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Product Information</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <FormInputComponent
                      id="productName"
                      name="productName"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={jsonLoanDetails?.Product?.Name}
                      required={false}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="productCode"
                      name="productCode"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={productCode}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="productType"
                      name="productType"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={ProductType[jsonLoanDetails?.Product?.ProductType] ? textFormatService.splitAtUpper(
                        ProductType[jsonLoanDetails?.Product?.ProductType]
                      ) : ""}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="financialGroup"
                      name="financialGroup"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={values?.financialGroup?.name}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              {/* Account Information */}
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Account Information</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <FormInputComponent
                      id="accountName"
                      name="accountName"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={accountName}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="accountNumber"
                      name="accountNumber"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={accountNumber}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="accountOfficer"
                      name="accountOfficer"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={accountOfficer}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    {values.loanType === "Individual" ? (
                      <FormInputComponent
                        id="bankVerificationNumber"
                        name="bankVerificationNumber"
                        type="text"
                        placeholder=""
                        divClass={6}
                        value={bankVerificationNumber}
                        required={true}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                        disabled={true}
                      />
                    ) : (
                      <FormInputComponent
                        id="businessRegistrationNumber"
                        name="businessRegistrationNumber"
                        type="text"
                        placeholder=""
                        divClass={6}
                        value={values.bvn}
                        required={true}
                        validated={validated}
                        errors={errors}
                        onChange={onChange}
                        disabled={true}
                      />
                    )}
                    <FormInputComponent
                      id="branch"
                      name="branch"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={branch}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="defaultingLoanInterest"
                      name="defaultingLoanInterest"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={defaultingLoanInterest}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    {/* <FormInputComponent
                      id="economicSector"
                      name="economicSector"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={economicSector}
                      required={this.getRequiredFields("firstName")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="securityDeposit"
                      name="securityDeposit"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={securityDeposit}
                      required={this.getRequiredFields("firstName")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="DisbursementMode"
                      name="DisbursementMode"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={disbursementMode}
                      required={this.getRequiredFields("firstName")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="typeOfLoan"
                      name="typeOfLoan"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={typeOfLoan}
                      required={this.getRequiredFields("firstName")}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    /> */}
                    <FormInputComponent
                      id="preferredRepaymentBank"
                      name="preferredRepaymentBank"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={preferredRepaymentBank}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="preferredRepaymentAccount"
                      name="preferredRepaymentAccount"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={preferredRepaymentAccount}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              {/* Loan Category
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Loan Category</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <FormInputComponent
                      id="loanCategory"
                      name="loanCategory"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={loanCategory}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="eligibleLoanLimit"
                      name="eligibleLoanLimit"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={eligibleLoanLimit}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="eligibleLoanTenor"
                      name="eligibleLoanTenor"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={eligibleLoanTenor}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="loanInterestRate"
                      name="loanInterestRate"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={loanInterestRate}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                  </div>
                </div>
              </div> */}

              {/* Loan Category */}
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Loan Category</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <SelectInputComponent
                      label="Loan Category"
                      id="loanCategory"
                      name="loanCategory"
                      divClass={6}
                      value={loanCategory}
                      items={loanCategoryInputData}
                      required={this.getRequiredFields("loanCategory")}
                      validated={validated}
                      errors={errors}
                      onChange={async (id, value) => {
                        await onChange(id, value)
                        await this.onSelectLoanCategory(value)
                      }}
                      disabled={!this.state.editLoanCategory}
                    />

                    {!this.state.hideEditButton ? (
                      <button
                        className="btn btn-outline-primary"
                        style={{ cursor: "pointer" }}
                        type="button"
                        onClick={() => { this.enableLoanCategory() }}
                      // disabled={!this.state.repaymentGenerated}
                      >
                        <React.Fragment>
                          <i className="fas fa-pencil-alt"></i>

                        </React.Fragment>
                      </button>
                    ) : ("")}


                    <FormInputComponent
                      id="eligibleLoanLimit"
                      name="eligibleLoanLimit"
                      type="number"
                      placeholder=""
                      divClass={6}
                      value={eligibleLoanLimit ? eligibleLoanLimit : selectedLoanCategory?.loanLimit}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="eligibleLoanTenor"
                      name="eligibleLoanTenor"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={eligibleLoanTenor ? eligibleLoanTenor : selectedLoanCategory?.loanTenor}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                    <FormInputComponent
                      id="loanInterestRate"
                      name="loanInterestRate"
                      type="text"
                      placeholder=""
                      divClass={6}
                      value={loanInterestRate ? loanInterestRate : selectedLoanCategory?.interestRate}
                      required={true}
                      validated={validated}
                      errors={errors}
                      onChange={onChange}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>


              {values.loanType === "Corporate" ? (
                <div className="card mt-3">
                  <div className="card-header clear-fix">
                    <h6
                      className="float-left"
                      style={{ marginBottom: "0px" }}
                    >
                      <b>Disbursement Details</b>
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="form-row">
                      <SelectInputComponent
                        label="Disburse to"
                        id="disburseTo"
                        name="disburseTo"
                        divClass={6}
                        value={disburseTo}
                        items={disburseToInputData}
                        required={this.getRequiredFields("disburseTo")}
                        validated={validated}
                        errors={errors}
                        onChange={async (id, value) => {
                          await onChange(id, value);
                          await this.onChangeDisburseTo(id, value)
                        }}
                        disabled={false}
                      />

                      {this.state.disburseTo === "Supplier" || values?.loanDetails?.disburseTo === "Supplier" ? (
                        <>
                          {values?.loanDetails?.supplierName === "" ? (
                            <>
                              <SelectInputComponent
                                label="Supplier Name"
                                id="supplierName"
                                name="supplierName"
                                divClass={6}
                                value={supplierName}
                                items={supplierNameInputData}
                                required={this.getRequiredFields("supplierName")}
                                validated={validated}
                                errors={errors}
                                onChange={async (id, value) => {
                                  await onChange(id, value)
                                  await this.onSelectSupplier(value)
                                }}
                                disabled={false} />
                            </>
                          ) : (
                            <>
                              <FormInputComponent
                                id="supplierName"
                                name="supplierName"
                                type="text"
                                placeholder=""
                                divClass={6}
                                value={supplierName ? supplierName : selectedSupplier?.supplierName}
                                required={this.getRequiredFields("supplierName")}
                                validated={validated}
                                errors={errors}
                                onChange={onChange}
                                disabled={true}
                              />
                            </>
                          )}

                          <FormInputComponent
                            id="supplierBankName"
                            name="supplierBankName"
                            type="text"
                            placeholder=""
                            divClass={6}
                            value={supplierBankName ? supplierBankName : selectedSupplier?.bankName}
                            required={this.getRequiredFields("supplierBankName")}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                            disabled={true}
                          />
                          <FormInputComponent
                            id="supplierAccountNumber"
                            name="supplierAccountNumber"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={supplierAccountNumber ? supplierAccountNumber : selectedSupplier?.accountNumber}
                            required={this.getRequiredFields("supplierAccountNumber")}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                            disabled={true}
                          />
                          <FormInputComponent
                            id="supplierPhoneNumber"
                            name="supplierPhoneNumber"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={supplierPhoneNumber ? supplierPhoneNumber : selectedSupplier?.phoneNumber}
                            required={this.getRequiredFields("supplierPhoneNumber")}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                            disabled={true}
                          />
                          <FormInputComponent
                            label="Supplier Address"
                            id="supplierAddress"
                            name="supplierAddress"
                            type="text"
                            placeholder=""
                            divClass={6}
                            value={supplierAddress ? supplierAddress : selectedSupplier?.address}
                            required={this.getRequiredFields("supplierAddress")}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                            disabled={true}
                          />
                        </>

                      ) : ("")}
                    </div>
                  </div>
                </div>

              ) : ("")}

              {/* Charges */}
              <div className="card mt-3">
                <div className="card-header clear-fix">
                  <h6
                    className="float-left"
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Charges</b>
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-12">
                      <div
                        className="table-responsize text-nowrap"
                        style={{ height: "150px", overflowY: "scroll" }}
                      >
                        <table className="table table-hover table-content table-sm table-striped mb-0">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  width: "5px",
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>#</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Name</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              >
                                <strong>Amount</strong>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingTop: "0.2rem",
                                  paddingBottom: "0.2rem",
                                }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(productPricing)}
                            {_.isEmpty(productPricing) ||
                              productPricing.length === 0 ? (
                              <tr>
                                <td
                                  className="font-weight-normal text-truncate"
                                  colSpan={4}
                                  style={{
                                    textAlign: "center",
                                    color: "#FE825E",
                                    fontSize: "small",
                                  }}
                                >
                                  ...No Charge available
                                </td>
                              </tr>
                            ) : (

                              productPricing.map((charge, id) => (
                                <tr key={id}>

                                  <td
                                    style={{
                                      width: "5px",
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {id + 1}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {charge.name}
                                  </td>
                                  <td
                                    style={{
                                      paddingTop: "0.2rem",
                                      paddingBottom: "0.2rem",
                                      fontSize: "small",
                                    }}
                                  >
                                    {
                                      charge.feeType === "Percentage" ?
                                        textFormatService.formatCurrency(((charge?.processingFee / 100) * (this.props.values?.loanAmount / 100)), 2) :
                                        textFormatService.formatCurrency(charge?.processingFee, 2)
                                    }
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Loan Details Action */}
              <div className="form-row">
                <div className="my-1 mx-2">
                  {(getCurrentUsersRoles().includes("updatedataentry") && (
                    <><button
                      type="submit"
                      className="btn btn-sm btn-primary"
                      disabled={this.state.submitting}
                      onClick={(e) => {
                        onHandleSubmit(
                          e,
                          ActionTypes.SAVE,
                          "Loan Details",
                          () => {
                            this.onFormSubmit(
                              {
                                loanAmount,
                                tenureInDays,
                                requestedLoanAmount,
                                requestedTenureInDays,
                                loanPurpose,
                                interestRate,
                                interestRateFrequency,
                                repaymentFrequencyPrincipal,
                                repaymentFrequencyInterest,
                                dueDate,
                                repaymentDay,
                                moratorium,
                                moratoriumPeriod,
                                impactOnTenor,
                                chargeInterest,
                                spreadInterestAcrossBalanceTenor,
                                productCode,
                                accountName,
                                accountNumber,
                                accountOfficer,
                                bankVerificationNumber,
                                branch,
                                defaultingLoanInterest,
                                economicSector,
                                securityDeposit,
                                disbursementMode,
                                typeOfLoan,
                                preferredRepaymentBank,
                                preferredRepaymentAccount,
                                charges,
                                accrual,
                                loanCategory,
                                loanInterestRate,
                                eligibleLoanLimit,
                                eligibleLoanTenor,
                                loanCommission,
                                supplierAccountNumber,
                                supplierBankName,
                                supplierAddress,
                                supplierName,
                                supplierPhoneNumber,
                                disburseTo
                              },
                              onReloadFields
                            );
                          }
                        );
                      }}
                    >
                      {this.state.submitting === false ? (
                        <React.Fragment>
                          <i className="fas fa-lg fa-save mr-3" /> Save
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <i className="fas fa-spin fa-circle-notch mr-3" />{" "}
                          Please wait...
                        </React.Fragment>
                      )}
                    </button>
                      <button
                        className="btn btn-sm btn-outline-primary my-2 mx-2"
                        style={{ cursor: "pointer" }}
                        type="button"
                        onClick={() => { this.toggleShowRepaymentSchedule(); }}
                      >
                        <React.Fragment>
                          <i className="fas fa-receipt fa-lg mr-2" />{" "}
                          View Repayment Schedule
                        </React.Fragment>
                      </button></>
                  ))}
                </div>
              </div>

              {/* <RepaymentSchedule
                item={(JSON.parse(JSON.parse(values.jsonData)))}
                showPatchModal={this.state.showRepaymentSchedule}
                togglePatchModal={this.toggleShowRepaymentSchedule}
              // values={this.state.repaymentDetails}
              /> */}

              <Repayment
                item={repaymentDetails}
                showPatchModal={this.state.showRepaymentSchedule}
                togglePatchModal={this.toggleShowRepaymentSchedule}
                values={values.loanDetails}
              />

            </div>
          </div>
        )}
      ></LoanDetailsForm>
    );
  }
}

export default LoanDetails;

