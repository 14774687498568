import React from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import activityTrailService from "../../../services/auditTrail/activityTrailService";
import BaseFormComponent, {
  IBaseFormState,
} from "../../../components/BaseFormComponent";
import { ActionTypes } from "../../../enums/actionTypes";
import creditAssessmentValidationService from "../../../services/creditAssessment/creditAssessmentValidationService";
import Skeleton from "react-loading-skeleton";
import DetailItem from "../../../components/detailItem";
import DetailItemInputComponent from "../../../components/detailItemInputBox";
import loanProductService from "../../../services/creditAssessment/loanProductService";
import DetailItemSelectComponent, {
  ISelectItems,
} from "../../../components/detailItemSelectBox";
import { RepaymentSchedule } from "../../../enums/repaymentSchedule";
import FormInputComponent from "../../../components/formInputComponent";
import textFormatService from "../../../services/utility/textFormatService";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { decodeToken } from "react-jwt";
import SelectInputComponent from "../../../components/formSelectComponent";

interface EditLoanProductFields {
  productName: string;
  productCode: string;
  minimumAmount: string;
  maximumAmount: string;
  tenure: string;
  // repaymentSchedule: string;
  scoreCard: string;

  guarantorCount?: string;
  minScore: string;
  maxScore: string;
  limit: string;
  tenor: string;
  pricing: string;
  repaymentFrequency: string;
}

class EditLoanProductForm extends BaseFormComponent<EditLoanProductFields> { }

interface IEditLoanProductProps {
  item: any;
  showEditModal: boolean;
  toggleEditModal: any;
  scoreCardList: any;
}
interface IEditLoanProductState {
  prices: any;
  ownUpdate: boolean;
  requireGuarantor: boolean;
}

class EditLoanProduct extends React.Component<
  IEditLoanProductProps,
  IBaseFormState & IEditLoanProductState
> {
  constructor(props: IEditLoanProductProps) {
    super(props);
    this.state = {
      validated: false,
      submitting: false,
      errors: {},
      prices: [],
      ownUpdate: false,

      requireGuarantor: false,
    };
  }

  onFormSubmit(fields: EditLoanProductFields, onReloadFieldsCB: any): boolean {
    try {
      this.setState(
        {
          errors:
            creditAssessmentValidationService.validateEditLoanProductForm(
              fields
            ),
          ownUpdate: true
        },
        () => {
          if (Object.keys(this.state.errors).length === 0) {
            this.submit(fields, onReloadFieldsCB);
          }
        }
      );
      this.setState({ validated: true, ownUpdate: true });
      return true;
    } catch (error) {
      return false;
    }
  }

  submit = async (fields: EditLoanProductFields, onReloadFieldsCB: any) => {
    if (Number(fields.minimumAmount) < 0 || Number(fields.maximumAmount) < 0) {
      toast.info(`You have some invalid numbers! `, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    } else if (Number(fields.minimumAmount) > Number(fields.maximumAmount)) {
      toast.info(`Minimum Amount cannot be greater than Maximum! `, {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      return false;
    }
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    this.setState({ submitting: true, ownUpdate: true });
    const payload = { ...this.props.item };
    payload.name = fields.productName;
    payload.productCode = fields.productCode;
    payload.minimumAmount = Number(fields.minimumAmount);
    payload.institutionCode = decodedToken?.["InstitutionCode"];
    payload.maximumAmount = Number(fields.maximumAmount);
    payload.tenure = Number(fields.tenure);
    payload.repaymentSchedule = "Weekly";
    // payload.repaymentSchedule = fields.repaymentSchedule;
    payload.scoreCardId = fields.scoreCard;
    payload.category = payload.loanProductCategory === "Credit Assessment" ? "CreditAssessment" : payload.loanProductCategory;
    // payload.requireGuarantor = this.state.requireGuarantor;
    // payload.scoreCardId = fields.scoreCard;
    // payload.guarantorCount = this.state.requireGuarantor ? Number(fields.guarantorCount) : 0;


    const loanPricings: any = []
    const loanPricing = [...this.state.prices];
    console.log(loanPricing)
    // console.log(payload)
    loanPricing.forEach(price => {
      const pricing: any = {};
      pricing.productName = payload.name
      pricing.productCode = payload.productCode
      pricing.institutionCode = payload.institutionCode
      pricing.maxScore = Number(price.maxScore)
      pricing.minScore = Number(price.minScore)
      pricing.limit = Number(price.limit)
      pricing.tenor = Number(price.tenor)
      pricing.pricing = Number(price.pricing)
      pricing.repaymentFrequency = price.repaymentFrequency
      loanPricings.push(pricing)

    });

    console.log(loanPricings)
    var createLoanPricing = await loanProductService.updateLoanProductPricing(loanPricings, payload.institutionCode, payload.productCode)
    if (createLoanPricing.status >= 200 && createLoanPricing.status <= 300) {
      //   console.log("For checks::", payload);
      const response = await loanProductService.updateLoanProduct(
        payload, payload.id
      );
      if (response.status >= 200 && response.status <= 300) {
        await activityTrailService.saveActivityTrail({
          actionType: "Edit Loan Product",
          description: `Edited details for Loan Product ${fields.productName}`,
        });

        toast.info(`Loan Product ${fields.productName} Edited successfully! `, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
        // return true;
      }
    }
    else {
      toast.info(`Loan Product Pricing Couldn't Edited! `, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      return false;
    }
    onReloadFieldsCB();
    this.setState({ submitting: false, ownUpdate: true }, () =>
      this.props.toggleEditModal(true, this.props.item)
    );

    this.setState({ submitting: false, ownUpdate: true });

  };

  static getDerivedStateFromProps(nextProps: IEditLoanProductProps, prevState) {
    if (prevState.ownUpdate) {
      return {
        ownUpdate: false,
      };
    }
    if (_.isEmpty(nextProps.item)) {
      return null;
    }
    return {
      prices: nextProps.item.pricing ? nextProps.item.pricing : [],
    }
  }

  handleOnAddPricing = async (item: any) => {

    if (Number(item.minScore) >= Number(item.maxScore)) {
      toast.info(`Maximum Score must be greater than Minimum Score`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      return false
    }

    if (Number(item.minScore) < 0) {
      toast.info(`Minimum Score must be 0 or greater`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      return false
    }
    if (Number(item.maxScore) > 100) {
      toast.info(`Maximum Score must be less than 100`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
      return false
    }
    const prices = [...this.state.prices];
    if (await this.validateRanges(item, prices)) {
      prices.push({
        minScore: item.minScore,
        maxScore: item.maxScore,
        limit: item.limit,
        tenor: item.tenor,
        pricing: item.pricing,
        repaymentFrequency: item.repaymentFrequency
      }
      );

      console.log(prices)
      this.setState({ prices, ownUpdate: true });
    }
    else {
      toast.info(`Range Overlap, Please Check Entries`, {
        autoClose: 6000,
        type: toast.TYPE.DEFAULT,
        hideProgressBar: false,
      });
    }

  };

  // handleReloadFields = (fields: AddProductConfigurationFields) => {
  //   fields.feeName = ""
  // }

  handleOnRemovePricing = (item: any) => {
    const prices = [...this.state.prices];
    if (prices.includes(item)) {
      prices.splice(
        prices.indexOf(item), 1
      );
    }
    this.setState({ prices, ownUpdate: true });
  };

  rangesOverlap = (a, b) => {
    return (Number(b.minScore) >= Number(a.minScore) && Number(b.minScore) <= Number(a.maxScore)) || (Number(b.maxScore) >= Number(a.minScore) && Number(b.maxScore) <= Number(a.maxScore));
  };

  validateRanges = (item, prices) => {
    // const prices = [...this.state.prices];
    for (let price of prices) {
      if (this.rangesOverlap(price, item)) {
        return false; // New range overlaps with existing, don't add it
      }
    }
    return true; // No overlapping ranges
  };

  resetFields() {
    this.setState({
      validated: false,
      submitting: false,
      errors: {},
      ownUpdate: true
    });
  }

  render() {
    const { item, showEditModal, toggleEditModal, scoreCardList } = this.props;
    const { validated, submitting, errors, prices } = this.state;

    // Turn enum into array
    let repaymentScheduleInputData: ISelectItems[] = Object.keys(
      RepaymentSchedule
    )
      .filter((value) => isNaN(Number(value)) === false)
      .map((key) => ({
        name: RepaymentSchedule[key],
        value: RepaymentSchedule[key],
      }));

    let repaymentFrequencyInputData: ISelectItems[] = [
      { name: "Daily", value: "Daily" },
      { name: "Weekly", value: "Weekly" },
      { name: "Monthly", value: "Monthly" },
      { name: "Quarterly", value: "Quarterly" },
      { name: "BiAnnually", value: "BiAnnually" },
      { name: "Yearly", value: "Yearly" },
    ];

    let scoreCardInputData: ISelectItems[] = scoreCardList?.map((item) => ({
      name: item.name,
      value: item.id,
    }));

    return (
      <Modal
        size="xl"
        backdrop="static"
        show={showEditModal}
        onHide={toggleEditModal}
      >
        <Modal.Header>
          <Modal.Title>
            <h5>
              <i className="fas fa-columns fa-sm cyan-text mr-3" />
              Edit Loan Product -{" "}
              <span className="font-weight-bold">{item.name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <EditLoanProductForm
          initialValues={{
            productName: item.name,
            productCode: item.productCode,
            minimumAmount: item.minimumAmount?.toString(),
            maximumAmount: item.maximumAmount?.toString(),
            tenure: item.tenure?.toString(),
            // repaymentSchedule: item.repaymentSchedule,
            minScore: "",
            maxScore: "",
            limit: "",
            tenor: "",
            pricing: "",
            repaymentFrequency: "",
            scoreCard: scoreCardList?.filter(x => x.id === item.scoreCard)[0]?.name
          }}
          FormComponent={({
            fields: {
              maximumAmount,
              minimumAmount,
              productName,
              tenure,
              productCode,
              // repaymentSchedule,
              maxScore,
              minScore,
              limit,
              tenor,
              pricing,
              repaymentFrequency,
              scoreCard
            },
            onChange,
            onReloadFields,
            onHandleSubmit,
          }) => (
            <React.Fragment>
              <Modal.Body>
                <div className="card">
                  <div className="card-header clear-fix">
                    {_.isEmpty(item) ? (
                      <Skeleton width={200} />
                    ) : (
                      <h6
                        className="card-title float-left"
                        style={{ marginBottom: "0px" }}
                      >
                        <b>General Information</b>
                      </h6>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Name"
                              value={productName}
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Product Code"
                              value={productCode}
                              labelSize={5}
                              valueSize={7}
                            />
                            {/* <DetailItem
                              label="Repayment Schedule"
                              value={
                                <DetailItemSelectComponent
                                  id="repaymentSchedule"
                                  name="repaymentSchedule"
                                  items={repaymentScheduleInputData}
                                  value={repaymentSchedule}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            /> */}
                          </dl>
                        )}
                      </div>
                      <div className="col-md-6">
                        {_.isEmpty(item) ? (
                          <Skeleton count={3} width={300} />
                        ) : (
                          <dl className="row">
                            <DetailItem
                              label="Tenure In Days"
                              value={
                                <DetailItemInputComponent
                                  id="tenure"
                                  type="number"
                                  name="tenure"
                                  placeholder=""
                                  value={tenure}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Minimum Amount"
                              value={
                                <DetailItemInputComponent
                                  id="minimumAmount"
                                  type="number"
                                  name="minimumAmount"
                                  placeholder=""
                                  value={minimumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            <DetailItem
                              label="Maximum Amount"
                              value={
                                <DetailItemInputComponent
                                  id="maximumAmount"
                                  type="number"
                                  name="maximumAmount"
                                  placeholder=""
                                  value={maximumAmount}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            />
                            {/* <DetailItem
                              label="Score Card"
                              value={
                                <DetailItemSelectComponent
                                  id="scoreCard"
                                  name="scoreCard"
                                  items={scoreCardInputData}
                                  value={scoreCard}
                                  required={true}
                                  validated={validated}
                                  errors={errors}
                                  onChange={onChange}
                                />
                              }
                              labelSize={5}
                              valueSize={7}
                            /> */}
                          </dl>
                        )}
                      </div>
                    </div>
                    <div className="card mt-3">
                      <div className="card-header clear-fix">
                        <h6
                          className="float-left"
                          style={{ marginBottom: "0px" }}
                        >
                          <b>Product Pricing</b>
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="form-row">
                          <FormInputComponent
                            label="Mininimum Score"
                            id="minScore"
                            name="minScore"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={minScore}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Maximum Score"
                            id="maxScore"
                            name="maxScore"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={maxScore}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Limit"
                            id="limit"
                            name="limit"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={limit}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Tenor"
                            id="tenor"
                            name="tenor"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={tenor}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />
                          <FormInputComponent
                            label="Interest Rate"
                            id="pricing"
                            name="pricing"
                            type="number"
                            placeholder=""
                            divClass={6}
                            value={pricing}
                            required={true}
                            validated={validated}
                            errors={errors}
                            onChange={onChange}
                          />

                          <SelectInputComponent
                            id="repaymentFrequency"
                            name="repaymentFrequency"
                            divClass={6}
                            value={repaymentFrequency}
                            items={repaymentFrequencyInputData}
                            required={true}
                            validated={false}
                            errors={errors}
                            onChange={onChange}
                          />
                          {/* Add Pricing */}
                          <div className="form-row">
                            <div className="my-1 mx-2">
                              <button
                                className="btn btn-sm btn-outline-primary my-4"
                                style={{ cursor: "pointer" }}
                                type="button"
                                onClick={() =>
                                  this.handleOnAddPricing(
                                    {
                                      maxScore,
                                      minScore,
                                      limit,
                                      tenor,
                                      pricing,
                                      repaymentFrequency
                                    },
                                  )
                                }
                              >
                                <React.Fragment>
                                  <i className="far fa-save fa-lg mr-2" />{" "}
                                  Add
                                </React.Fragment>
                              </button>
                            </div>
                          </div>
                          <div className=" form-row col-md-12">
                            <div
                              className="table-responsive text-nowrap"
                              style={{
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              <table className="table table-hover table-content table-sm table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Min Score</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Max Score</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Limit</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Tenor</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Interest Rate</strong>
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      <strong>Repayment Frequency</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {prices.map((item) => (
                                    <tr key={prices.indexOf(item)}>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {prices.indexOf(item) + 1}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.minScore}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.maxScore}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {textFormatService.formatCurrency(Number(item.limit), 2)}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.tenor}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.pricing}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        {item.repaymentFrequency}
                                      </td>
                                      <td
                                        style={{
                                          paddingTop: "0.2rem",
                                          paddingBottom: "0.2rem",
                                          fontSize: "small",
                                        }}
                                      >
                                        <i
                                          className="far fa-trash-alt ml-5 red-text fa-lg"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.handleOnRemovePricing(
                                              item
                                            )
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => {
                    onHandleSubmit(
                      e,
                      ActionTypes.UPDATE,
                      "Loan Product",
                      () => {
                        this.onFormSubmit(
                          {
                            productCode,
                            tenure,
                            productName,
                            minimumAmount,
                            maximumAmount,
                            // repaymentSchedule,
                            scoreCard,
                            maxScore,
                            minScore,
                            limit,
                            tenor,
                            pricing,
                            repaymentFrequency
                          },
                          onReloadFields
                        );
                      }
                    );
                  }}
                  disabled={submitting}
                >
                  {submitting === false ? (
                    <React.Fragment>
                      <i className="fas fa-lg fa-save mr-3" /> Save
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <i className="fas fa-spin fa-circle-notch" />
                    </React.Fragment>
                  )}
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => toggleEditModal(false, this.props.item)}
                  disabled={submitting}
                >
                  <i className="fas fa-times mr-3" />
                  Close
                </Button>
              </Modal.Footer>
            </React.Fragment>
          )}
        ></EditLoanProductForm>
      </Modal>
    );
  }
}

export default EditLoanProduct;
