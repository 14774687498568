import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import swal from "sweetalert";
import mandateService from "../../../services/mandate/mandateService";
import textFormatService from "../../../services/utility/textFormatService";
import { MandateBulkUploadType } from "../../../enums/mandateBulkUploadType";
import institutionsService from "../../../services/auth/institutionsService";
import { decodeToken } from "react-jwt";
import { getCurrentUserSession } from "../../../services/auth/authService";
import { Button, Modal } from "react-bootstrap";
import { filterSuccessfulIncomingReversalsReport } from "../../../services/sweep/reportsService";
import SelectInputComponent from "../../../components/formSelectComponent";
import { ISelectItems } from "../../../components/detailItemSelectBox";

interface IUploadMandatesProps {}
interface IUploadMandatesState {
  mandateRecordsDoc: any;
  mandateRecordsKey: any;
  mandateRecordErrors: any;
  validatingMandateRecords: boolean;
  mandateRecordsValidations: any;
  loanApplicationFormsDoc: any;
  loanApplicationFormsKey: any;
  loanApplicationFormsErrors: any;
  validatingLoanApplicationForms: boolean;
  loanApplicationFormsValidations: any;
  rightOfSetOffDoc: any;
  rightOfSetOffKey: any;
  rightOfSetOffErrors: any;
  validatingRightOfSetOff: boolean;
  rightOfSetOffValidations: any;
  submitting: boolean;
  templateUri: string;
  institutionBulkMandateConfig: string;
  allInstitutions: any;
  institution: any;
  fetchingInstitutions: boolean;
}

class UploadMandates extends Component<
  IUploadMandatesProps,
  IUploadMandatesState
> {
  constructor(props: IUploadMandatesProps) {
    super(props);
    this.state = {
      mandateRecordsDoc: {},
      mandateRecordsKey: Date.now(),
      mandateRecordErrors: [],
      validatingMandateRecords: false,
      mandateRecordsValidations: {},
      loanApplicationFormsDoc: {},
      loanApplicationFormsKey: Date.now(),
      loanApplicationFormsErrors: [],
      validatingLoanApplicationForms: false,
      loanApplicationFormsValidations: {},
      rightOfSetOffDoc: {},
      rightOfSetOffKey: Date.now(),
      rightOfSetOffErrors: [],
      validatingRightOfSetOff: false,
      rightOfSetOffValidations: {},
      allInstitutions: [],
      institution: {},
      fetchingInstitutions: false,
      submitting: false,
      templateUri:
        process.env.REACT_APP_MANDATE_BASEADDRESS +
        `Mandate/DownloadBulkMandateTemplate`,
      institutionBulkMandateConfig:
        MandateBulkUploadType.MandatesOnly.toString(),
    };
  }

  async componentDidMount() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    // console.log("testing: ", decodedToken);
    let allInsts = institutionsService
      .retrieveAllInstitutions2()
      .then((response) => {
        // const decodedToken = decodeToken(getCurrentUserSession().token || "");
        this.setState({
          allInstitutions: response.data,
        });
        // this.handleOnSelectInstitution(parseInt(decodedToken?.["InstitutionId"]), "");
      })
      .catch((err) => {
        console.log("error retrieving institutions: ", err);
      });

    const institution = await institutionsService.retrieveInstitutionById(
      decodedToken?.["InstitutionId"] || 0
    );
    // console.log(institution.data);
    this.setState({
      institutionBulkMandateConfig: institution.data.mandateBulkUploadType,
    });
  }

  handleOnSelectInstitution = (id, value) => {
    try {
      const institution = this.state.allInstitutions.filter(
        (x) => x.id === parseInt(value)
      )[0];
      // console.log("institution to set: ", institution);
      this.setState({ institution });
    } catch (error) {
      toast.info("Something went wrong.", {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
      toast.info(error.response.data.errors[0], {
        autoClose: 6000,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
      });
    }
  };

  handleOnMandateRecordDocumentChange = async (e) => {
    // console.log("mandate records: ", e.target.files[0]);
    e.preventDefault();
    if (!["xlsx"].includes(e.target.files[0].name.split(".").slice(-1)[0])) {
      toast.error(
        "File format is not supported, please upload a valid .xlsx document"
      );
    } else if (e.target.files[0].size > 700000) {
      toast.error(
        "Kindly ensure that the size of the document is less than 700 kb"
      );
    } else {
      this.setState({
        mandateRecordsDoc: e.target.files[0],
        validatingMandateRecords: true,
      });
      const payload: any = {};
      payload.mandateRecords = await textFormatService.getBase64StringFromFile(
        e.target.files[0]
      );
      let response: any = null;
      try {
        response = await mandateService.validateMandateRecords(payload);
        // console.log("response: ", response);
        this.setState({
          mandateRecordsValidations: response.data.item1,
          mandateRecordErrors: response.data.item2,
          validatingMandateRecords: false,
        });
      } catch (error) {
        console.log(error.response);
        toast.error(
          "Something went wrong, kindly ensure that you have valid data in your document"
        );
        this.setState({ validatingMandateRecords: false });
      }
      // console.log("final check: ", this.state.mandateRecordsDoc);
    }
  };

  handleOnLoanApplicationFormsChange = async (e) => {
    e.preventDefault();
    var loanapplicationformszip = e.target.files[0];
    // console.log("inspection: ", loanapplicationformszip);
    if (
      !["zip", "rar"].includes(
        loanapplicationformszip.name.split(".").slice(-1)[0]
      )
    ) {
      toast.error(
        "Please confirm that the document is a valid Zip or RAR file"
      );
    } else if (e.target.files[0].size > 20000000) {
      toast.error(
        "Kindly ensure that the size of the document is less than 20 MB"
      );
    } else {
      this.setState({
        loanApplicationFormsDoc: loanapplicationformszip,
        validatingLoanApplicationForms: true,
      });
      const payload: any = {};
      payload.mandateRecords = await textFormatService.getBase64StringFromFile(
        this.state.mandateRecordsDoc
      );
      payload.loanApplicationForms = {
        document: await textFormatService.getBase64StringFromFile(
          loanapplicationformszip
        ),
        archiveType: loanapplicationformszip.name.split(".").slice(-1)[0],
      };
      let response: any = null;
      try {
        response = await mandateService.validateLoanApplicationForms(payload);
      } catch (error) {
        this.setState({ validatingLoanApplicationForms: false });
      }
      this.setState({
        loanApplicationFormsValidations: response.data.item1,
        loanApplicationFormsErrors: response.data.item2,
        validatingLoanApplicationForms: false,
      });
    }
  };

  handleOnRightOfSetOffChange = async (e) => {
    e.preventDefault();
    var rightofsetoffzip = e.target.files[0];
    // console.log("inspect ros: ", rightofsetoffzip);
    if (
      !["zip", "rar"].includes(rightofsetoffzip.name.split(".").slice(-1)[0])
    ) {
      toast.error(
        "Please confirm that the document is a valid Zip or RAR file"
      );
    } else if (rightofsetoffzip.size > 20000000) {
      toast.error(
        "Kindly ensure that the size of the document is less than 20 MB"
      );
    } else {
      this.setState({
        rightOfSetOffDoc: rightofsetoffzip,
        validatingRightOfSetOff: true,
      });
      const payload: any = {};
      payload.mandateRecords = await textFormatService.getBase64StringFromFile(
        this.state.mandateRecordsDoc
      );
      payload.rightOfSetOffs = {
        document: await textFormatService.getBase64StringFromFile(
          rightofsetoffzip
        ),
        archiveType: rightofsetoffzip.name.split(".").slice(-1)[0],
      };
      let response: any = null;
      try {
        response = await mandateService.validateRightOfSetOffs(payload);
      } catch (error) {
        this.setState({ validatingRightOfSetOff: false });
      }
      this.setState({
        rightOfSetOffValidations: response.data.item1,
        rightOfSetOffErrors: response.data.item2,
        validatingRightOfSetOff: false,
      });
    }
  };

  handleOnPushForManualExamination = async (e) => {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    swal({
      title: "Confirm!",
      text: `The document you are about to upload clearly contains several errors. They would be manually reviewed and we would attempt to reach out to you to adjust the document if need be. This process might take a longer time as opposed to you directly reviewing the entries, adjusting the entries and trying again. Are you sure you wanna proceed?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
      dangerMode: false,
    }).then(async (resp) => {
      if (resp) {
      } else {
        try {
          this.setState({ submitting: true });

          const {
            mandateRecordsDoc,
            loanApplicationFormsDoc,
            rightOfSetOffDoc,
          } = this.state;
          const payload: any = {};
          payload.mandateRecords =
            await textFormatService.getBase64StringFromFile(mandateRecordsDoc);
          payload.documentName = mandateRecordsDoc.name;
          payload.institutionId = decodedToken?.["InstitutionId"];
          // payload.rightOfSetOffs = {
          //   document: await textFormatService.getBase64StringFromFile(
          //     rightOfSetOffDoc
          //   ),
          //   archiveType: rightOfSetOffDoc.name.split(".").slice(-1)[0],
          // };
          // payload.loanApplicationForms = {
          //   document: await textFormatService.getBase64StringFromFile(
          //     loanApplicationFormsDoc
          //   ),
          //   archiveType: loanApplicationFormsDoc.name.split(".").slice(-1)[0],
          // };
          var response = await mandateService.uploadMandates(payload, true);
          // console.log("response: ", response);
          toast.info("Mandates have been uploaded successfully.", {
            autoClose: 6000,
            type: toast.TYPE.INFO,
            hideProgressBar: false,
          });
          this.resetAllFields();
        } catch (error) {
          this.setState({ submitting: false });
        }
      }
    });
  };

  handleOnSubmit = async (e) => {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");
    console.log("inst: ", this.state.institution);
    console.log("current guy: ", decodedToken?.["InstitutionId"]);
    if (
      _.isEmpty(this.state.institution) &&
      decodedToken?.["InstitutionId"] === "1"
    ) {
      return toast.info(
        "Please select a valid institution to upload the mandates for",
        {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        }
      );
    }
    swal({
      title: "Confirm!",
      text: `Are you sure you want to proceed to upload these mandates?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
      dangerMode: false,
    }).then(async (resp) => {
      if (resp) {
        // console.log("cancelling action...");
      } else {
        try {
          this.setState({ submitting: true });
          const {
            mandateRecordsDoc,
            loanApplicationFormsDoc,
            rightOfSetOffDoc,
          } = this.state;
          const payload: any = {};
          payload.mandateRecords =
            await textFormatService.getBase64StringFromFile(mandateRecordsDoc);
          payload.documentName = mandateRecordsDoc.name;
          payload.institutionId = decodedToken?.["InstitutionId"];
          // payload.rightOfSetOffs = {
          //   document: await textFormatService.getBase64StringFromFile(
          //     rightOfSetOffDoc
          //   ),
          //   archiveType: rightOfSetOffDoc.name.split(".").slice(-1)[0],
          // };
          // payload.loanApplicationForms = {
          //   document: await textFormatService.getBase64StringFromFile(
          //     loanApplicationFormsDoc
          //   ),
          //   archiveType: loanApplicationFormsDoc.name.split(".").slice(-1)[0],
          // };
          var response = await mandateService.uploadMandates(payload, false);
          // console.log("response: ", response);
          toast.info("Mandates have been uploaded successfully.", {
            autoClose: 6000,
            type: toast.TYPE.INFO,
            hideProgressBar: false,
          });
          this.resetAllFields();
        } catch (error) {
          this.setState({ submitting: false });
        }
        // this.props.handleOnEnableOrDisable(this.props.item);
        //this.submit({ actionType: actionType, entityName: entityName });
      }
    });
  };

  resetAllFields() {
    // console.log("about to reset all fields...");
    this.setState({
      mandateRecordsDoc: {},
      mandateRecordsKey: Date.now(),
      mandateRecordErrors: [],
      validatingMandateRecords: false,
      mandateRecordsValidations: {},
      loanApplicationFormsDoc: {},
      loanApplicationFormsKey: Date.now(),
      loanApplicationFormsErrors: [],
      validatingLoanApplicationForms: false,
      loanApplicationFormsValidations: {},
      rightOfSetOffDoc: {},
      rightOfSetOffKey: Date.now(),
      rightOfSetOffErrors: [],
      validatingRightOfSetOff: false,
      rightOfSetOffValidations: {},
      submitting: false,
    });
  }

  resetLoanApplicationFormFields() {
    this.setState({
      loanApplicationFormsDoc: {},
      loanApplicationFormsKey: Date.now(),
      loanApplicationFormsErrors: [],
      validatingLoanApplicationForms: false,
      loanApplicationFormsValidations: {},
    });
  }

  resetRightOfSetOffFields() {
    this.setState({
      rightOfSetOffDoc: {},
      rightOfSetOffKey: Date.now(),
      rightOfSetOffErrors: [],
      validatingRightOfSetOff: false,
      rightOfSetOffValidations: {},
    });
  }

  render() {
    const decodedToken = decodeToken(getCurrentUserSession().token || "");

    const {
      mandateRecordsDoc,
      mandateRecordsKey,
      mandateRecordErrors,
      validatingMandateRecords,
      mandateRecordsValidations,
      loanApplicationFormsDoc,
      loanApplicationFormsKey,
      loanApplicationFormsErrors,
      validatingLoanApplicationForms,
      loanApplicationFormsValidations,
      rightOfSetOffDoc,
      rightOfSetOffKey,
      rightOfSetOffErrors,
      validatingRightOfSetOff,
      rightOfSetOffValidations,
      submitting,
      fetchingInstitutions,
      institution,
      allInstitutions,
      institutionBulkMandateConfig,
    } = this.state;
    let institutionsInputData: ISelectItems[] = allInstitutions.map((item) => ({
      name: item.name,
      value: item.id,
    }));
    institutionsInputData.sort((a, b) => a.name.localeCompare(b.name));
    // console.log(MandateBulkUploadType.AllDocuments.toString())
    // console.log(institutionBulkMandateConfig!==MandateBulkUploadType.AllDocuments.toString())

    return (
      <React.Fragment>
        <div className="container-fluid relative animatedParent animateOnce">
          <div className="animated fadeInUpShort go">
            <div className="row my-3 mx-2">
              <h3>
                <b>Upload Mandates Management</b>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <i className="fas fa-align-left mr-3" />
                    Mandate Upload Guide
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <h6>Rules</h6>
                          <ul>
                            <li className="brown-text small">
                              Maximum number of Mandates that can be created at
                              a time is <b style={{ color: "red" }}>2000</b>.
                            </li>
                            <li className="brown-text small">
                              All Loan References <b>MUST</b> be distinct for
                              every record on the list.
                            </li>
                            <li className="brown-text small">
                              Every record must have a corresponding{" "}
                              <b>Loan Application Form </b>
                              and <b>Right of Set-Off</b> document.
                            </li>
                            <li className="brown-text small">
                              Each document's name must be its corresponding
                              Loan Reference Number.
                            </li>
                            <li className="brown-text small">
                              Valid document formats for both{" "}
                              <b>Loan Application Form</b> &{" "}
                              <b>Right of Set-Off</b> are{" "}
                              <b style={{ color: "red" }}>
                                docx, doc, pdf, jpg, png
                              </b>
                              .
                            </li>
                            <li className="brown-text small">
                              Valid document formats for compressed{" "}
                              <b>Loan Application Forms</b> &{" "}
                              <b>Right of Set-Offs</b> are{" "}
                              <b style={{ color: "red" }}>zip, rar</b>.
                            </li>
                            <li className="brown-text small">
                              Maximum size for compressed{" "}
                              <b>Loan Application Forms</b> &{" "}
                              <b>Right of Set-Offs</b> is{" "}
                              <b style={{ color: "red" }}>20 MB</b>.
                            </li>
                            <li className="brown-text small">
                              Maximum size for each document is{" "}
                              <b style={{ color: "red" }}>200 Kb</b>.
                            </li>
                            <li className="brown-text small">
                              Click{" "}
                              <b>
                                <a
                                  href={this.state.templateUri}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Here
                                </a>
                              </b>{" "}
                              to download a sample template for bulk mandate
                              creation.
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <h6>Record Template</h6>
                          <ul>
                            <li className="brown-text small">
                              [BVN] Bank Verification Number
                              {/* <span style={{ color: "red" }}>
                        <b>Required If BRN or TIN is not set</b>
                      </span> */}
                            </li>
                            {/* <li className="brown-text small">
                      [BRN] Business Registration Number (
                      <span style={{ color: "red" }}>
                        <b>Required If BVN is not set</b>
                      </span>
                      )
                    </li>
                    <li className="brown-text small">
                      [TIN] Tax Identification Number (
                      <span style={{ color: "red" }}>
                        <b>Required If BVN is not set </b>
                      </span>
                      )
                    </li> */}
                            <li className="brown-text small">Phone Number</li>
                            <li className="brown-text small">Loan Amount</li>
                            <li className="brown-text small">
                              Total Repayment Expected
                            </li>
                            <li className="brown-text small">Loan Reference</li>
                            <li className="brown-text small">
                              Linked Account Number
                            </li>
                            <li className="brown-text small">Customer ID</li>
                            <li className="brown-text small">Customer Name</li>
                            <li className="brown-text small">
                              Sweep Type (
                              <b>
                                <span style={{ color: "red" }}>
                                  Reactive, Proactive, Both
                                </span>
                              </b>
                              )
                            </li>
                            <li className="brown-text small">
                              Loan Request Date (
                              <b>
                                <span style={{ color: "red" }}>
                                  Format: yyyy-mm-dd
                                </span>
                              </b>
                              )
                            </li>
                            <li className="brown-text small">
                              Consent Receipt Channel (
                              <b>
                                <span style={{ color: "red" }}>
                                  USSD, SMS, MobileApplication, InternetBanking,
                                  Signature, Web, OtherDigitalChannel
                                </span>
                              </b>
                              )
                            </li>
                            <li className="brown-text small">
                              Loan Expiration Date (
                              <b>
                                <span style={{ color: "red" }}>
                                  Format: yyyy-mm-dd
                                </span>
                              </b>
                              )
                            </li>
                            <li className="brown-text small">Loan Tenure</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header card-footer"
                    style={{ padding: "8px" }}
                  >
                    <i className="fas fa-upload mr-3" />
                    Document Upload
                  </div>
                  <div className="card-content" style={{ padding: "8px" }}>
                    <div className="row">
                      <div className="col-md-4 col-sm-4">
                        <h5 className="text-center" style={{ fontWeight: 400 }}>
                          Documents
                        </h5>
                        <div className="form-group">
                          <label
                            htmlFor="mandateRecords"
                            className="textbox-label"
                          >
                            MANDATE RECORDS DOCUMENT
                          </label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                key={mandateRecordsKey}
                                className="custom-file-input"
                                id="mandateRecordsDoc"
                                name="mandateRecordsDoc"
                                aria-describedby="inputGroupFileAddon01"
                                onChange={(e) =>
                                  this.handleOnMandateRecordDocumentChange(e)
                                }
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="mandateRecordsDoc"
                              >
                                {_.isEmpty(mandateRecordsDoc.name)
                                  ? "Choose File"
                                  : mandateRecordsDoc.name}
                              </label>
                            </div>
                            {_.isEmpty(mandateRecordsDoc.name) ? (
                              ""
                            ) : (
                              <React.Fragment>
                                {validatingMandateRecords === true ? (
                                  <span style={{ float: "right" }}>
                                    <i className="fas fa-spinner fa-spin m-3"></i>
                                  </span>
                                ) : (
                                  <span style={{ float: "right" }}>
                                    <i
                                      className="far fa-trash-alt red-text m-3"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.resetAllFields()}
                                    ></i>
                                  </span>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                          <br />
                          {decodedToken?.["InstitutionId"] === "1" && (
                            <SelectInputComponent
                              id="institution"
                              name="institution"
                              divClass={12}
                              value={institution.id}
                              items={institutionsInputData}
                              required={false}
                              validated={false}
                              errors={[]}
                              labelIconClass={
                                fetchingInstitutions === true
                                  ? "fas fa-spinner fa-spin orange-text mr-2"
                                  : ""
                              }
                              onChange={this.handleOnSelectInstitution}
                            />
                          )}
                        </div>

                        {institutionBulkMandateConfig !==
                        MandateBulkUploadType[
                          MandateBulkUploadType.AllDocuments
                        ] ? (
                          ""
                        ) : (
                          <React.Fragment>
                            {Object.values(mandateRecordsValidations).filter(
                              (x) => x === false
                            ).length > 0 ||
                            _.isEmpty(mandateRecordsValidations) ? (
                              ""
                            ) : (
                              <React.Fragment>
                                <div className="form-group">
                                  <label
                                    htmlFor="loanApplicationForms"
                                    className="textbox-label"
                                  >
                                    LOAN APPLICATION FORMS
                                  </label>
                                  <div className="input-group">
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        key={loanApplicationFormsKey}
                                        className="custom-file-input"
                                        id="loanApplicationFormsDoc"
                                        name="loanApplicationFormsDoc"
                                        aria-describedby="inputGroupFileAddon02"
                                        onChange={(e) =>
                                          this.handleOnLoanApplicationFormsChange(
                                            e
                                          )
                                        }
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="loanApplicationFormsDoc"
                                      >
                                        {_.isEmpty(loanApplicationFormsDoc.name)
                                          ? "Choose File"
                                          : loanApplicationFormsDoc.name}
                                      </label>
                                    </div>
                                    {_.isEmpty(loanApplicationFormsDoc.name) ? (
                                      ""
                                    ) : (
                                      <React.Fragment>
                                        {validatingLoanApplicationForms ===
                                        true ? (
                                          <span style={{ float: "right" }}>
                                            <i className="fas fa-spinner fa-spin m-3"></i>
                                          </span>
                                        ) : (
                                          <span style={{ float: "right" }}>
                                            <i
                                              className="far fa-trash-alt red-text m-3"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.resetLoanApplicationFormFields()
                                              }
                                            ></i>
                                          </span>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="rightOfSetOff"
                                    className="textbox-label"
                                  >
                                    RIGHT OF SET-OFF DOCUMENTS
                                  </label>
                                  <div className="input-group">
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        key={rightOfSetOffKey}
                                        className="custom-file-input"
                                        id="rightOfSetOffDoc"
                                        name="rightOfSetOffDoc"
                                        aria-describedby="inputGroupFileAddon01"
                                        onChange={(e) =>
                                          this.handleOnRightOfSetOffChange(e)
                                        }
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="rightOfSetOffDoc"
                                      >
                                        {_.isEmpty(rightOfSetOffDoc.name)
                                          ? "Choose File"
                                          : rightOfSetOffDoc.name}
                                      </label>
                                    </div>
                                    {_.isEmpty(rightOfSetOffDoc.name) ? (
                                      ""
                                    ) : (
                                      <React.Fragment>
                                        {validatingRightOfSetOff === true ? (
                                          <span style={{ float: "right" }}>
                                            <i className="fas fa-spinner fa-spin m-3"></i>
                                          </span>
                                        ) : (
                                          <span style={{ float: "right" }}>
                                            <i
                                              className="far fa-trash-alt red-text m-3"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.resetRightOfSetOffFields()
                                              }
                                            ></i>
                                          </span>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                      <div className="col-md-8 col-sm-8">
                        <h5 className="text-center" style={{ fontWeight: 400 }}>
                          <i className="fas fa-tasks mr-2"></i>Validations
                        </h5>
                        <hr />
                        <div className="row">
                          <div className="col-md-4 col-sm-4">
                            <h6 style={{ fontWeight: 400 }}>Mandate Records</h6>
                            <ul style={{ listStyle: "none" }}>
                              <li className="brown-text small">
                                {_.isEmpty(
                                  JSON.stringify(
                                    mandateRecordsValidations.maximumMandatesPerRequest
                                  )
                                ) ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : JSON.stringify(
                                    mandateRecordsValidations.maximumMandatesPerRequest
                                  ) === "true" ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                Mandate records are not more than 5000.
                              </li>
                              <li className="brown-text small">
                                {_.isEmpty(
                                  JSON.stringify(
                                    mandateRecordsValidations.entriesAreValid
                                  )
                                ) ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : JSON.stringify(
                                    mandateRecordsValidations.entriesAreValid
                                  ) === "true" ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                Entries are Valid.
                              </li>
                              <li className="brown-text small">
                                {_.isEmpty(
                                  JSON.stringify(
                                    mandateRecordsValidations.allLoanReferencesAreDistinct
                                  )
                                ) ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : JSON.stringify(
                                    mandateRecordsValidations.allLoanReferencesAreDistinct
                                  ) === "true" ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                All Loan References are Distinct.
                              </li>
                              <li className="brown-text small">
                                {_.isEmpty(
                                  JSON.stringify(
                                    mandateRecordsValidations.noExistingMandateForAllRecords
                                  )
                                ) ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : JSON.stringify(
                                    mandateRecordsValidations.noExistingMandateForAllRecords
                                  ) === "true" ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                There is no existing mandate for any of the
                                records.
                              </li>
                              <li className="brown-text small">
                                {_.isEmpty(
                                  JSON.stringify(
                                    mandateRecordsValidations.noneOfTheBVNsHaveBeenGreyListed
                                  )
                                ) ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : JSON.stringify(
                                    mandateRecordsValidations.noneOfTheBVNsHaveBeenGreyListed
                                  ) === "true" ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                None of the BVNs have been grey listed.
                              </li>
                              <li className="brown-text small">
                                {_.isEmpty(
                                  JSON.stringify(
                                    mandateRecordsValidations.allBVNsAreValid
                                  )
                                ) ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : JSON.stringify(
                                    mandateRecordsValidations.allBVNsAreValid
                                  ) === "true" ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                All BVNs are valid.
                              </li>
                              <li className="brown-text small">
                                {_.isEmpty(
                                  JSON.stringify(
                                    mandateRecordsValidations.mandateLimitNotReached
                                  )
                                ) ? (
                                  <i className="far fa-circle mr-2"></i>
                                ) : JSON.stringify(
                                    mandateRecordsValidations.mandateLimitNotReached
                                  ) === "true" ? (
                                  <i className="fas fa-check-circle green-text mr-2"></i>
                                ) : (
                                  <i className="fas fa-times-circle red-text mr-2"></i>
                                )}
                                Mandate Limit not passed.
                              </li>
                            </ul>
                          </div>
                        </div>
                        {mandateRecordErrors.concat(
                          loanApplicationFormsErrors,
                          rightOfSetOffErrors
                        ).length === 0 ? (
                          ""
                        ) : (
                          <div className="row">
                            <h6 style={{ fontWeight: 400 }}>
                              Validation Details
                            </h6>
                            <div
                              className="table-responsive text-nowrap"
                              style={{ height: "150px", overflowY: "scroll" }}
                            >
                              <table className="table table-hover table-content table-sm table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        width: "5px",
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      #
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        paddingTop: "0.2rem",
                                        paddingBottom: "0.2rem",
                                      }}
                                    >
                                      Description
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {mandateRecordErrors
                                    .concat(
                                      loanApplicationFormsErrors,
                                      rightOfSetOffErrors
                                    )
                                    .map((error) => (
                                      <tr
                                        key={textFormatService.generateGUID()}
                                      >
                                        <td
                                          style={{
                                            width: "5px",
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          {mandateRecordErrors
                                            .concat(
                                              loanApplicationFormsErrors,
                                              rightOfSetOffErrors
                                            )
                                            .indexOf(error) + 1}
                                        </td>
                                        <td
                                          style={{
                                            paddingTop: "0.2rem",
                                            paddingBottom: "0.2rem",
                                            fontSize: "small",
                                          }}
                                        >
                                          {error}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <React.Fragment>
                    <Modal.Footer>
                      <Fragment>
                        {!_.isNull(mandateRecordErrors) &&
                          mandateRecordErrors.length > 0 && (
                            <Button
                              size="sm"
                              variant="outline-primary"
                              disabled={submitting}
                              onClick={(e) =>
                                this.handleOnPushForManualExamination(e)
                              }
                            >
                              {submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-4" /> Push
                                  for manual assessment
                                </React.Fragment>
                              ) : (
                                <i className="fas fa-spin fa-circle-notch" />
                              )}
                            </Button>
                          )}
                        {Object.values(mandateRecordsValidations).filter(
                          (x) => x === false
                        ).length === 0 &&
                          !_.isEmpty(mandateRecordsValidations) &&
                          (institutionBulkMandateConfig !==
                            MandateBulkUploadType[
                              MandateBulkUploadType.AllDocuments
                            ] ||
                            (Object.values(rightOfSetOffValidations).filter(
                              (x) => x === false
                            ).length === 0 &&
                              !_.isEmpty(rightOfSetOffValidations) &&
                              Object.values(
                                loanApplicationFormsValidations
                              ).filter((x) => x === false).length === 0 &&
                              !_.isEmpty(loanApplicationFormsValidations))) && (
                            <Button
                              size="sm"
                              variant="primary"
                              disabled={submitting}
                              onClick={(e) => this.handleOnSubmit(e)}
                            >
                              {submitting === false ? (
                                <React.Fragment>
                                  <i className="fas fa-lg fa-save mr-4" /> Save
                                </React.Fragment>
                              ) : (
                                <i className="fas fa-spin fa-circle-notch" />
                              )}
                            </Button>
                          )}
                      </Fragment>
                    </Modal.Footer>
                  </React.Fragment>
                  {/* {Object.values(mandateRecordsValidations).filter((x) => x === false).length === 0 &&
                                    !_.isEmpty(mandateRecordsValidations) &&
                                    (institutionBulkMandateConfig !== MandateBulkUploadType[MandateBulkUploadType.AllDocuments] ||
                                        (Object.values(rightOfSetOffValidations).filter((x) => x === false).length === 0 &&
                                            !_.isEmpty(rightOfSetOffValidations) &&
                                            Object.values(loanApplicationFormsValidations).filter((x) => x === false).length === 0 &&
                                            !_.isEmpty(loanApplicationFormsValidations))) ? (
                                        
                                    ) : (
                                        ""
                                    )} */}
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadMandates;
