import React, { Component, useContext } from "react";
import {
  Button,
  Card,
  useAccordionToggle,
  AccordionContext,
  Accordion,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import DetailItem from "../../../../components/detailItem";
import { toast } from "react-toastify";
import loanRequestService from "../../../../services/hybridAppraisal/loanRequestService";
import { IFilterData } from "../../../../components/BaseListComponent";
import { createFilterData, mapEntityList } from "../../../../services/utility/autoMapperService";
import { filterFieldData } from "../../../../components/tableComponents/itemsTable";
import appraisalActionService from "../../../../services/hybridAppraisal/appraisalActionService";
import textFormatService from "../../../../services/utility/textFormatService";

export function ContextAwareToggle({ eventKey, header }) {
  const currentEventKey = useContext(AccordionContext);
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;
  const angleType = isCurrentEventKey ? "fa fa-angle-up" : "fa fa-angle-down";
  return (
    <Card.Header onClick={toggleOnClick} style={{ cursor: "pointer" }}>
      <b>{header}</b>
      <i
        className={angleType}
        aria-hidden="true"
        style={{ float: "right" }}
      ></i>
    </Card.Header>
  );
}

interface IBankOneCreditCheckProps {
  item: any;
}

interface IBankOneCreditCheckState {
  item: any;
  updatingStatus: boolean;
  submitting: boolean;
  data: IFilterData;
  currentPage: number;
  tableItems: any;
  fetching: boolean;
  fetchingDetail: boolean;
  totalSize: number;
  filterValueInput: filterFieldData;
  userRoles: any;
  systemProcess: any
  creditAnalysisResponse: any;
  ownUpdate: boolean;
}

class BankOneCreditCheck extends Component<IBankOneCreditCheckProps, IBankOneCreditCheckState> {
  _isMounted: boolean;
  constructor(props: IBankOneCreditCheckProps) {
    super(props);
    this.state = {
      item: {},
      updatingStatus: false,
      submitting: true,
      data: createFilterData([]) as IFilterData,
      currentPage: 1,
      fetching: false,
      fetchingDetail: false,
      tableItems: [],
      totalSize: 0,
      filterValueInput: [],
      userRoles: [],
      systemProcess: [],
      creditAnalysisResponse: [],
      ownUpdate: false
    };
  }

  static getDerivedStateFromProps(nextProps: IBankOneCreditCheckProps, prevState) {
    return {
      item: nextProps.item,
    };
  }

  getActionTypeString = (actionType) => {
    switch (actionType) {
      case 2:
        return "Update";
      case 6:
        return "Approve";
      case 7:
        return "Decline";
      case 8:
        return "PushBack";
      case 9:
        return "Recall";
      default:
        return "";
    }
  };

  onFormSubmit(
    actionType,
  ): boolean {
    try {
      var action = this.getActionTypeString(actionType)
      this.setState(
        {
          // errors: appraisalValidationService.validateAppraisalProcessDetailForm(fields),
        },
        () => {
          return this.submit(action);

        }
      );
      this.setState({});
      return false;
    } catch (error) {
      return false;
    }
  }
  async submit(
    action
  ): Promise<boolean> {
    try {
      this.setState({ submitting: true });
      const payload: any = {};
      var curDT = new Date().toLocaleString()

      payload.action = action;
      payload.loanRequestId = this.props.item.id
      payload.systemProcessID = Number(3);
      payload.itemLevel = this.props.item.currentLevel;
      payload.errorMessage = "";
      payload.logType = "SystemType";
      payload.comment = "";
      payload.effectiveStatus = "Valid";
      payload.id = Number(0);
      payload.processResponse = {
        "actionId": 0,
        "score": 0,
        "result": "string",
        "response": "string",
        "id": 0,
        "dateCreated": curDT,
        "dateUpdated": curDT
      };
      payload.dateCreated = curDT;
      payload.dateUpdated = curDT;

      // console.log("payload: ", payload);
      const response = await appraisalActionService.RunSystemProcess(payload);
      if (response.status >= 200 && response.status <= 300) {
        toast.info(`Validation Initiated successfully!`, {
          autoClose: 6000,
          type: toast.TYPE.DEFAULT,
          hideProgressBar: false,
        });
      }
      this.setState({ submitting: false });
    }
    catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.detail, {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.errors[0], {
          autoClose: 6000,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
        });
      }
    } finally {
      this.setState({ submitting: false });
      return true;
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    const creditBureauResp = this.props.item.appraisalActions?.filter(x => x.systemProcessID === Number(15) && x.effectiveStatus === "Valid")[0]?.processResponse;
    let creditAnalysisResponse: any = []
    try {
      creditAnalysisResponse = JSON.parse(creditBureauResp?.response);
    }
    catch {
      creditAnalysisResponse = []
    }
    console.log(creditAnalysisResponse)
    if (this._isMounted) {
      this.setState({ creditAnalysisResponse: creditAnalysisResponse, ownUpdate: true });
    }
  }

  handlePageChange = async (currentPage: number, data?: IFilterData) => {
    currentPage = currentPage < 0 ? this.state.currentPage : currentPage;
    this.setState({ fetching: true });
    let dataClone = typeof data === "undefined" ? { ...this.state.data } : { ...data };
    try {
      const loanRequests = await loanRequestService.filterLoanRequests(
        dataClone,
        Number(process.env.REACT_APP_DEFAULT_PAGESIZE) || 10,
        currentPage
      );
      this.setState({
        tableItems: mapEntityList({
          entityList: loanRequests.data.item2,
          properties: [
            { oldName: "customerName", newName: "Name" },
            { oldName: "phonenumber", newName: "phoneNumber" },
            { oldName: "gender", newName: "gender" },
            { oldName: "gender", newName: "gender" },
          ],
        }),
        currentPage,
        totalSize: loanRequests.data.item1,
        fetching: false,
      });
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status <= 499) {
        this.setState({ fetching: false });
      } else {
        this.setState({ fetching: false });
      }
    } finally {
      this.setState({ fetching: false });
    }
  };

  getFilteredItems = (data: IFilterData): IFilterData => {
    let filteredItems: IFilterData = {};
    Object.keys(data).forEach((key) => {
      if (data[key].item2 !== "") {
        filteredItems[key] = data[key];
      }
    });
    return filteredItems;
  };

  handleOnExport = async (type: string) => { };

  render() {
    const {
      item,
    } = this.props;

    const { creditAnalysisResponse
    } = this.state

    return (
      <div className="container-fluid relative animatedParent animateOnce">
        <div className="animated fadeInUpShort go">
          <div className="row">
            <div className="col-md-12">
              <div className="form-row">
                <div className="col-md-12">
                  <div className="row my-3 mx-2">
                    <h3>
                      <b>BankOne Credit Check</b>
                    </h3>
                  </div>
                  <div className="card">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <ContextAwareToggle
                          eventKey={`0`}
                          header={<React.Fragment>
                          </React.Fragment>}
                        ></ContextAwareToggle>
                        <Accordion.Collapse eventKey={`0`}>
                          <Card.Body>
                            <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <div className="row">
                                {item.appraisalActions.length === 0 ? (
                                  <React.Fragment>
                                    <div>
                                      <Skeleton height={50} />
                                    </div>
                                    <div>
                                      <Skeleton height={50} />
                                    </div>
                                    <div>
                                      <Skeleton height={50} />
                                    </div>
                                    <div>
                                      <Skeleton height={50} />
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <div className="col-md-5">
                                      <h6
                                        className="card-title"
                                        style={{ marginBottom: "0px" }}
                                      >
                                        <b>Customer Details</b>
                                      </h6>
                                      <br />
                                      <dl className="row">
                                        <DetailItem
                                          label="Customer Name"
                                          labelSize={5}
                                          valueSize={7}
                                          value={creditAnalysisResponse?.CustomerName}
                                        />
                                        <DetailItem
                                          label="Date of Birth"
                                          labelSize={5}
                                          valueSize={7}
                                          value={creditAnalysisResponse?.DateOfBirth === null || creditAnalysisResponse?.DateOfBirth === "" ? "N/A" : textFormatService.formatShortTimeString(creditAnalysisResponse?.DateOfBirth)}
                                        />
                                        <DetailItem
                                          label="BVN"
                                          labelSize={5}
                                          valueSize={7}
                                          value={creditAnalysisResponse?.BVN}
                                        />
                                        <DetailItem
                                          label="Gender"
                                          labelSize={5}
                                          valueSize={7}
                                          value={creditAnalysisResponse?.Gender}
                                        />
                                        <DetailItem
                                          label="Phone number 1"
                                          labelSize={5}
                                          valueSize={7}
                                          value={creditAnalysisResponse?.PhoneNumber1}
                                        />
                                        <DetailItem
                                          label="Phone number 2"
                                          labelSize={5}
                                          valueSize={7}
                                          // value={JSON.parse(JSON.parse(JSON.parse(creditAnalysisResponse.jsonData)).LoanDetails).ProductName}
                                          value={creditAnalysisResponse?.PhoneNumber2}
                                        />
                                      </dl>
                                    </div>
                                    <div className="col-md-5">
                                      <h6
                                        className="card-title"
                                        style={{ marginBottom: "0px", fontFamily: "sans-serif" }}
                                      >
                                        <b>Performance Summary</b>
                                      </h6>
                                      <br />
                                      <dl className="row">
                                        <DetailItem
                                          label="Number of Active Loan Accounts"
                                          labelSize={9}
                                          valueSize={3}
                                          // value={JSON.parse(JSON.parse(JSON.parse(item.jsonData)).LoanDetails).ProductName}
                                          value={creditAnalysisResponse?.NoOfOpenAccounts}
                                        />
                                        <DetailItem
                                          label="Number of Closed Loan Accounts"
                                          labelSize={9}
                                          valueSize={3}
                                          value={creditAnalysisResponse?.NoOfClosedAccounts}
                                        />
                                        <DetailItem
                                          label="Number of Performing Active Loan Accounts"
                                          labelSize={9}
                                          valueSize={3}
                                          value={creditAnalysisResponse?.NoOfPerformingOpenAccounts}
                                        />
                                        <DetailItem
                                          label="Number of Non-Performing Active Loan Accounts"
                                          labelSize={9}
                                          valueSize={3}
                                          value={creditAnalysisResponse?.NoOfDelinquentOpenAccounts}
                                        />
                                        <DetailItem
                                          label="Number of Loan Accounts Written-off"
                                          labelSize={9}
                                          valueSize={3}
                                          value={creditAnalysisResponse?.NoOfAccountsWrittenOff}
                                        />
                                      </dl>
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>

                  <div className="card mt-3">
                    {item.appraisalActions.length === 0 ? (("")) : (
                      <div
                        className="table-responsive text-nowrap"
                        style={{
                          height: "300px",
                          overflowY: "scroll",
                        }}
                      >
                        <table className="table table-hover table-content table-sm table-striped mb-0">
                          <thead>
                            <tr>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Customer Name</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Account Number</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Product Name</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Amount</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Tenor</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Disbursement Date</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Maturity Date</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Balance</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Overdue</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>No of Days Past Due</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Status</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Classification</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Date Closed</strong>
                              </th>
                              <th scope="col" style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", }}>
                                <strong>Amount Written Off</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.isEmpty(item.appraisalActions.filter(x => x.systemProcessID == Number(15) && x.effectiveStatus === "Valid")) && _.isEmpty(item.appraisalActions.filter(x => x.action == "Processed")) ? (("")) : (
                              item.appraisalActions.filter(x => x.systemProcessID == Number(15) && x.effectiveStatus === "Valid").map((actions) => (
                                actions?.processResponse?.result == "No loan history found" ? ("No loan history found") : (
                                  // console.log(creditAnalysisResponse?.CreditDetails)
                                  creditAnalysisResponse?.CreditDetails?.map(x => (
                                    <tr>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {creditAnalysisResponse?.CustomerName}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {x.AccountNumber}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {x.CreditFacilityType}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {/* {x.LoanAmount} */}
                                        {textFormatService.formatCurrency(Number(x.LoanAmount / 100), 2)}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {x.LoanTenor}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {textFormatService.formatShortTimeString(x.LoanDisbursementDate)}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {textFormatService.formatShortTimeString(x.LoanMaturityDate)}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {/* {x.LoanBalance} */}
                                        {textFormatService.formatCurrency(Number(x.LoanBalance), 2)}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {/* {x.AmountOverdue} */}
                                        {textFormatService.formatCurrency(Number(x.AmountOverdue), 2)}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {x.NoOfDaysPastDue}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {x.LoanStatus}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {x.LoanClassification}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {x.DateClosed === null ? "" : textFormatService.formatTimeString(x.DateClosed)}
                                      </td>
                                      <td style={{ width: "5px", paddingTop: "0.2rem", paddingBottom: "0.2rem", borderRight: "1px solid #ccc", fontSize: "small", }}>
                                        {/* {x.AmountWrittenOff} */}
                                        {textFormatService.formatCurrency(Number(x.AmountWrittenOff), 2)}
                                      </td>
                                    </tr>
                                  ))
                                ))
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>

                  <div className="card mt-3">

                    <Accordion defaultActiveKey="0">
                      <div>
                        <Card>
                          <ContextAwareToggle
                            eventKey={`0`}
                            header={<React.Fragment>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ color: "#878a8a" }}
                              ></i>{" "}
                              BankOne Credit Analysis Result
                            </React.Fragment>}
                          ></ContextAwareToggle>
                          <Accordion.Collapse eventKey={`0`}>
                            <Card.Body>
                              <div style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <dl className="row">
                                      <DetailItem
                                        label="Performance"
                                        labelSize={5}
                                        valueSize={5}
                                        value=
                                        {item.appraisalActions.filter(x => x.systemProcessID === Number(15) && x.effectiveStatus === "Valid")[0]?.action !== "Processed" ? (
                                          <i
                                            className=""
                                            aria-hidden="true"
                                            style={{ color: "#60ed15" }}
                                          >Processing.....</i>) :
                                          item.appraisalActions.filter(x => x.systemProcessID === Number(15) && x.effectiveStatus === "Valid")[0]?.processResponse?.result === "Pass" ||
                                            item.appraisalActions.filter(x => x.systemProcessID === Number(15) && x.effectiveStatus === "Valid")[0]?.processResponse?.result === "No loan history found" ?
                                            <i
                                              className=""
                                              aria-hidden="true"
                                              style={{ color: "#60ed15" }}
                                            >Good</i> : <i
                                              className=""
                                              aria-hidden="true"
                                              style={{ color: "#fcce54" }}
                                            >Bad</i>
                                        }
                                      />
                                    </dl>
                                  </div>

                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </div>
                    </Accordion>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankOneCreditCheck;
